const equipmentType = [
      {
            id: 1,
            value: 'any',
            label: 'Any'
      },
      {
            id: 2,
            value: 'grader',
            label: 'Grader'
      },
      {
            id: 3,
            value: 'trencher',
            label: 'Trencher'
      },
      {
            id: 4,
            value: 'truck',
            label: 'Truck'
      },
      {
            id: 5,
            value: 'backhoeloader',
            label: 'Backhoe Loader'
      },
      {
            id: 6,
            value: 'loader',
            label: 'Loader'
      },
      {
            id: 7,
            value: 'bulldozer',
            label: 'Bulldozer'
      },
      {
            id: 8,
            value: 'backhoe',
            label: 'Backhoe'
      },
      {
            id: 9,
            value: 'tractor',
            label: 'Tractor'
      },
      {
            id: 10,
            value: 'dumptruck',
            label: 'Dumptruck'
      },
      {
            id: 11,
            value: 'crane',
            label: 'Crane'
      },
      {
            id: 12,
            value: 'compactor',
            label: 'Compactor'
      }
];

const trackType = [
      { id: 1, value: 'wheeled', label: 'Wheeled' },
      { id: 2, value: 'trackpad_rubber', label: 'Trackpad Rubber' },
      { id: 3, value: 'trackpad_steel', label: 'Trackpad Steel' }
];

const sizeCategory = [
      { id: 1, value: 'micro', label: 'Micro' },
      { id: 2, value: 'small', label: 'Small' },
      { id: 3, value: 'medium', label: 'Medium' },
      { id: 4, value: 'large', label: 'Large' }
];

const equipmentStatus = [
      { id: 1, value: 'enrollment_pending', label: 'Enrollment Pending' },
      { id: 2, value: 'available', label: 'Available' },
      { id: 3, value: 'reserved', label: 'Reserved' },
      { id: 4, value: 'awaiting_project_start', label: 'Awaiting Project Start' },
      { id: 5, value: 'needs_repair', label: 'Needs Repair' },
      { id: 6, value: 'nearing_project_completion', label: 'Nearing Project Completion' },
      { id: 7, value: 'under_maintenance', label: 'Under Maintenance' },
      { id: 8, value: 'paused', label: 'Paused' }
];

const equipmentSize = [
      {
            id: 1,
            name: 'any',
            label: 'Any'
      },
      {
            id: 2,
            name: 'small',
            label: 'Small'
      },
      {
            id: 3,
            name: 'medium',
            label: 'Medium'
      },
      {
            id: 4,
            name: 'large',
            label: 'Large'
      }
];

const equipmentAvailability = [
      {
            id: 1,
            value: 'now',
            label: 'Now'
      },
      {
            id: 2,
            value: 'jul',
            label: 'Jul 2023'
      },
      {
            id: 3,
            value: 'aug',
            label: 'Aug 2023'
      },
      {
            id: 4,
            value: 'sept',
            label: 'Sept 2023'
      },
      {
            id: 5,
            value: 'oct',
            label: 'Oct 2023'
      },
      {
            id: 6,
            value: 'nov',
            label: 'Nov 2023'
      },
      {
            id: 7,
            value: 'dec',
            label: 'Dec 2023'
      }
];

const equipmentOwners = [
      {
            id: 1,
            value: 0,
            label: 'All Owners'
      },
      {
            id: 2,
            value: 1246,
            label: "Demitech"
      },
      {
            id: 3,
            value: 5267,
            label: "Davao Equipments"
      },
      {
            id: 4,
            value: 5267,
            label: "FZI Equipments"
      }
];

const equipmentsTable = [
      {
            id: 'DTC-0001',
            owner: 'Demitech',
            type: 'Backhoe',
            model: 'Komatsu PC30-3',
            status: 'available',
            last_active: 'March 13, 2023',
            est_availability: 'Now',
            commisstion_rate: '100%'
      },
      {
            id: 'DTC-0003',
            owner: 'Demitech',
            type: 'Backhoe',
            model: 'Komatsu PC60',
            status: 'active',
            last_active: 'June 23, 2023',
            est_availability: 'Aug 15, 2023',
            commisstion_rate: '100%'
      },
      {
            id: 'DVE-0056',
            owner: 'Davao Equipments',
            type: 'Backhoe',
            model: 'Hyundai Robex 200',
            status: 'available',
            last_active: 'March 13, 2023',
            est_availability: 'Now',
            commisstion_rate: '10%'
      },
      {
            id: 'DVE-0048',
            owner: 'Davao Equipments',
            type: 'Backhoe',
            model: 'Hitachi EX120',
            status: 'active',
            last_active: 'June 03, 2023',
            est_availability: 'Sep 04, 2023',
            commisstion_rate: '10%'
      },
      {
            id: 'FZI-0021',
            owner: 'FZI Construction',
            type: 'Backhoe',
            model: 'Komatsu PC120',
            status: 'active',
            last_active: 'June 03, 2023',
            est_availability: 'Sep 04, 2023',
            commisstion_rate: '10%'
      }
];

const projectStatus = [
      {
            id: 1,
            value: 'completed',
            label: 'Completed'
      },
      {
            id: 2,
            value: 'in_progress',
            label: 'In-progress'
      }
]

export { 
      equipmentSize, 
      equipmentType, 
      equipmentAvailability, 
      equipmentOwners, 
      equipmentsTable, 
      projectStatus,
      equipmentStatus,
      trackType,
      sizeCategory
}