export const authTypes = {
      LOGIN_SUCCESS: 'LOGIN_SUCCESS',
      LOGIN_FAILURE: 'LOGIN_FAILURE',

      LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
      CLEAR_CACHE: 'CLEAR_CACHE',

      VALIDATE_TOKEN_SUCCESS: 'VALIDATE_TOKEN_SUCCESS',
      VALIDATE_TOKEN_FAILED: 'VALIDATE_TOKEN_FAILED',
      
      VERIFY_AUTH_SUCCESS: 'VERIFY_AUTH_SUCCESS',
      VERIFY_AUTH_FAILED: 'VERIFY_AUTH_FAILED',

      GOOGLE_REGISTRATION_SUCCESS: 'GOOGLE_REGISTRATION_SUCCESS',
      GOOGLE_SIGNIN_SUCCESS: 'GOOGLE_SIGNIN_SUCCESS',

      FACEBOOK_REGISTRATION_SUCCESS: 'FACEBOOK_REGISTRATION_SUCCESS',
      FACEBOOK_SIGNIN_SUCCESS: 'FACEBOOK_SIGNIN_SUCCESS'
};