import { Chip, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import useProject from "../../../../../hooks/useProject";
import { useEffect, useState } from "react";
import moment from "moment/moment";

export default function ProjectsTable({ }) {

      const {
            isFetchingProjects,
            openEditProjectDrawer
      } = useProject();

      const [projectList, setProjectList] = useState([]);

      const [page, setPage] = useState(0);

      const [rowsPerPage, setRowsPerPage] = useState(5);

      const handleChangePage = (e, newPage) => {
            setPage(newPage);
      };

      const rows = () => {
            projectList.sort((a, b) => moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1)
            return projectList;
      };

      const handleChangeRowsPerPage = (e) => {
            setRowsPerPage(parseInt(e.target.value, 10));
            setPage(0);
      };

      return (
            <>
                  <TableContainer sx={{ minWidth: '50vh', maxHeight: '50vh' }}>
                        <Table stickyHeader>
                              <TableHead>
                                    <TableRow>
                                          {
                                                tableHeader.map((row) => (
                                                      <TableCell align="left" key={row.id}>
                                                            <Typography variant="subtitle2" color={'#51cbce'} fontWeight={700}>{row.label}</Typography>
                                                      </TableCell>
                                                ))
                                          }
                                    </TableRow>
                              </TableHead>
                              <TableBody>
                                    {
                                          rows()?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map(row => (
                                                      <TableRow
                                                            key={row.id}
                                                            hover
                                                            sx={{
                                                                  '&:last-child td, &:last-child th': {
                                                                        border: 0
                                                                  },
                                                                  cursor: 'pointer'
                                                            }}
                                                            onClick={() => openEditProjectDrawer(row.id)}
                                                      >
                                                            <TableCell align="left">
                                                                  {row.name}
                                                            </TableCell>

                                                            <TableCell align="left">
                                                                  {row.location}
                                                            </TableCell>

                                                            <TableCell align="left">
                                                                  ₱ {row.budget.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                  {row.start_date ? moment(row.start_date).format("dddd, MMMM Do YYYY") : 'N/A'}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                  {row.contract_duration} CD
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                  {
                                                                        (row.status === 'in_progress')
                                                                              ? <Chip size="small" label="On Going" variant="outlined" sx={{ backgroundColor: '#fbc658', fontWeight: 700 }} />
                                                                              : <Chip size="small" label="Completed" variant="outlined" sx={{ backgroundColor: '#6bd098', fontWeight: 700 }} />
                                                                  }
                                                            </TableCell>
                                                      </TableRow>
                                                ))
                                    }
                              </TableBody>
                        </Table>
                  </TableContainer>

                  <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        component="div"
                        count={projectList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                              mt: 5
                        }}
                  />
            </>
      )
}

const tableHeader = [
      { id: 1, label: 'TITLE' },
      { id: 2, label: 'LOCATION' },
      { id: 3, label: 'BUDGET' },
      { id: 4, label: 'START DATE' },
      { id: 5, label: 'DURATION' },
      { id: 6, label: 'STATUS' }
]