import { Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"

export default function PayoutsTable() {

      return (
            <TableContainer sx={{ minWidth: '50vh', maxHeight: '50vh' }}>
                  <Table stickyHeader>
                        <TableHead>
                              <TableRow>
                                    {
                                          tableHeader.map((row) => (
                                                <TableCell align="left" key={row.id}>
                                                      <Typography variant="subtitle2" color={'#51cbce'} fontWeight={700}>{row.label}</Typography>
                                                </TableCell>
                                          ))
                                    }
                              </TableRow>
                        </TableHead>
                        <TableBody>
                              {
                                    tableRow.map(row => (
                                          <TableRow
                                                key={row.id}
                                                hover
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                          >
                                                <TableCell align="left">{row.project}</TableCell>
                                                <TableCell align="left">{row.from_account}</TableCell>
                                                <TableCell align="left">{row.payable_to}</TableCell>
                                                <TableCell align="left">₱ {row.amount}</TableCell>
                                                <TableCell align="left">{row.check_date}</TableCell>
                                                <TableCell align="left">{row.purpose}</TableCell>
                                                <TableCell align="left">
                                                      <Chip size="small" label={row.acknowledge} variant="outlined" sx={{ backgroundColor: '#fbc658', fontWeight: 700 }} />
                                                </TableCell>
                                          </TableRow>
                                    ))
                              }
                        </TableBody>
                  </Table>
            </TableContainer>
      )
}


const tableRow = [
      {
            id: 1,
            project: 'Construction of 2 classrooms',
            from_account: 'RCBC - Demitech 200018216219',
            payable_to: 'John J Mayer',
            amount: '32,524.00',
            check_date: 'June 25, 2023',
            purpose: 'Rental',
            acknowledge: 'Acknowledge'
      },
      {
            id: 2,
            project: 'Maasim Pipe Laying',
            from_account: 'RCBC - Demitech 200018216219',
            payable_to: 'John J Mayer',
            amount: '32,524.00',
            check_date: 'June 25, 2023',
            purpose: 'Commission',
            acknowledge: 'Acknowledge'
      },
      {
            id: 3,
            project: 'Construction of 2 classrooms',
            from_account: 'RCBC - Demitech 200018216219',
            payable_to: 'John J Mayer',
            amount: '32,524.00',
            check_date: 'June 25, 2023',
            purpose: 'Rental',
            acknowledge: 'Acknowledge'
      },
      {
            id: 4,
            project: 'Lumasal Pipe Laying',
            from_account: 'RCBC - Demitech 200018216219',
            payable_to: 'John J Mayer',
            amount: '5,150.00',
            check_date: 'June 25, 2023',
            purpose: 'Mobilization',
            acknowledge: 'Acknowledge'
      },
      {
            id: 5,
            project: 'Lumasal Pipe Laying',
            from_account: 'RCBC - Demitech 200018216219',
            payable_to: 'John J Mayer',
            amount: '3,550.00',
            check_date: 'June 25, 2023',
            purpose: 'Hauling',
            acknowledge: 'Acknowledge'
      }
];


const tableHeader = [
      { id: 1, label: 'PROJECT' },
      { id: 2, label: 'FROM ACCOUNT' },
      { id: 3, label: 'PAYABLE TO' },
      { id: 4, label: 'AMOUNT' },
      { id: 5, label: 'CHECK DATE' },
      { id: 6, label: 'PURPOSE' },
      { id: 7, label: 'ACKNOWLEDGE' }
]