import { globalTypes } from "./global.types";

export const globalInitialState = {
      isNavBarOpen: true,
      isPageLoading: false,
      isRightDrawerToggled: false
}

export const global = (state = globalInitialState, action) => {
      switch (action.type) {
            case globalTypes.TOGGLE_DRAWER:
                  return {
                        ...state,
                        isNavBarOpen: !state.isNavBarOpen
                  };
            case globalTypes.RELOADING_PAGE:
                  return {
                        ...state,
                        isPageLoading: !state.isPageLoading
                  };
            default:
                  return state;
      }
}