import { Box, Button, Divider, Grid, Paper, Stack, TextField, Typography } from "@mui/material"
import AutoComplete from "../../../../../components/Autocomplete"
import { equipmentStatus, equipmentType, sizeCategory, trackType } from "../../../../../statics"
import { LoadingButton } from "@mui/lab"
import AddIcon from '@mui/icons-material/Add';
import useEquipment from "../../../../../hooks/useEquipment";
import EquipmentOwnerModal from "../modals/addEquipmentOwnerModal";
import { useDispatch } from "react-redux";
import { equipmentActions } from "../../../../../redux";


export default function AddEquipmentDrawer() {

      const dispatch = useDispatch();

      const {
            isAddEquipmentOwnerModalOpen,
            openAddEquipmentDrawer
      } = useEquipment();

      return (
            <>
                  <Box
                        marginTop={2}
                        paddingX={5}
                        paddingY={2}
                        sx={{ flexGrow: 1 }}
                  >
                        <Typography variant="h6" sx={{ mb: 3 }}>Equipment Model</Typography>

                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 8 }}>
                              <Grid item xs={2} sm={4} md={4} key={1}>
                                    <TextField
                                          name="make"
                                          label="Make"
                                          fullWidth
                                          placeholder="Make"
                                          variant="outlined"
                                    />
                              </Grid>
                              <Grid item xs={2} sm={4} md={4} key={2}>
                                    <TextField
                                          name="model"
                                          fullWidth
                                          label="Model"
                                          placeholder="Model"
                                          variant="outlined"
                                    />

                              </Grid>
                              <Grid item xs={2} sm={4} md={4} key={3}>
                                    <AutoComplete
                                          label={'Equipment Type'}
                                          placeholder="Equipment Type"
                                          fullWidth
                                          listItem={equipmentType}
                                    />
                              </Grid>
                              <Grid item xs={2} sm={4} md={4} key={4}>
                                    <TextField
                                          name="suggested_similarity"
                                          label="Suggested Similarity"
                                          placeholder="Suggested Similarity"
                                          fullWidth
                                          variant="outlined"
                                    />
                              </Grid>
                              <Grid item xs={2} sm={4} md={4} key={5}>
                                    <AutoComplete
                                          label={'Track Type'}
                                          placeholder="Track Type"
                                          fullWidth
                                          listItem={trackType}
                                    />
                              </Grid>
                              <Grid item xs={2} sm={4} md={4} key={6}>
                                    <AutoComplete
                                          label={'Size Category'}
                                          placeholder="Size Category"
                                          fullWidth
                                          listItem={sizeCategory}
                                    />
                              </Grid>
                        </Grid>

                        <Divider sx={{ my: 5 }} />

                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 8 }}>
                              <Grid item xs={2} sm={4} md={4} key={7}>
                                    <AutoComplete
                                          label={'Project'}
                                          placeholder="Select Project"
                                          fullWidth
                                          PaperComponent={({ children }) => {
                                                return (
                                                      <Paper>
                                                            {children}
                                                            <Button
                                                                  fullWidth
                                                                  variant="contained"
                                                                  sx={{
                                                                        justifyContent: "center",
                                                                        background: '#51cbce',
                                                                        '&:hover': {
                                                                              background: '#51cbce'
                                                                        }
                                                                  }}
                                                                  onMouseDown={() => {
                                                                        // console.log("Add new");
                                                                  }}
                                                            >
                                                                  <AddIcon /> Add Project
                                                            </Button>
                                                      </Paper>
                                                );
                                          }}
                                    />
                              </Grid>
                              <Grid item xs={2} sm={4} md={4} key={8}>
                                    <AutoComplete
                                          label={'Equipment Owner'}
                                          placeholder="Select Owner"
                                          fullWidth
                                          PaperComponent={({ children }) => {
                                                return (
                                                      <Paper>
                                                            {children}
                                                            <Button
                                                                  fullWidth
                                                                  variant="contained"
                                                                  sx={{
                                                                        justifyContent: "center",
                                                                        background: '#51cbce',
                                                                        '&:hover': {
                                                                              background: '#51cbce'
                                                                        }
                                                                  }}
                                                                  onMouseDown={() => dispatch(equipmentActions.equipmentOwnerModal())}
                                                            >
                                                                  <AddIcon /> Add Equipment Owner
                                                            </Button>
                                                      </Paper>
                                                );
                                          }}
                                    />
                              </Grid>
                              <Grid item xs={2} sm={4} md={4} key={9}>
                                    <AutoComplete
                                          label={'Dedicated Operator'}
                                          placeholder="Select Operator"
                                          fullWidth
                                          PaperComponent={({ children }) => {
                                                return (
                                                      <Paper>
                                                            {children}
                                                            <Button
                                                                  fullWidth
                                                                  variant="contained"
                                                                  sx={{
                                                                        justifyContent: "center",
                                                                        background: '#51cbce',
                                                                        '&:hover': {
                                                                              background: '#51cbce'
                                                                        }
                                                                  }}
                                                                  onMouseDown={() => {
                                                                        // console.log("Add new");
                                                                  }}
                                                            >
                                                                  <AddIcon /> Add Operator
                                                            </Button>
                                                      </Paper>
                                                );
                                          }}
                                    />
                              </Grid>
                              <Grid item xs={2} sm={4} md={4} key={10}>
                                    <AutoComplete
                                          label={'Status'}
                                          placeholder="Status"
                                          fullWidth
                                          listItem={equipmentStatus}
                                    />
                              </Grid>
                              <Grid item xs={2} sm={4} md={4} key={11}>
                                    <TextField
                                          name="current_lat"
                                          label="Current Latitude"
                                          placeholder="Current Latitude"
                                          fullWidth
                                          variant="outlined"
                                    />
                              </Grid>
                              <Grid item xs={2} sm={4} md={4} key={12}>
                                    <TextField
                                          name="current_lng"
                                          label="Current Longitude"
                                          placeholder="Current Longitude"
                                          fullWidth
                                          variant="outlined"
                                    />
                              </Grid>
                              <Grid item xs={2} sm={4} md={4} key={13}>
                                    <TextField
                                          name="subcon_hour_rate"
                                          label="Subcon Hour Rate"
                                          placeholder="Subcon Hour Rate"
                                          fullWidth
                                          variant="outlined"
                                    />
                              </Grid>
                              <Grid item xs={2} sm={4} md={4} key={14}>
                                    <TextField
                                          name="subcon_month_rate"
                                          label="Subcon Month Rate"
                                          placeholder="Subcon Month Rate"
                                          fullWidth
                                          variant="outlined"
                                    />
                              </Grid>

                              <Grid item xs={2} sm={4} md={4} key={15}>
                                    <TextField
                                          name="demitech_commision"
                                          label="Demitech Commision"
                                          placeholder="Demitech Commision"
                                          fullWidth
                                          variant="outlined"
                                    />
                              </Grid>

                              <Grid item xs={2} sm={4} md={4} key={16}></Grid>
                        </Grid>

                        <LoadingButton
                              variant="contained"
                              disableRipple
                              fullWidth
                              // loading={isSubmitting}
                              disableTouchRipple
                              sx={{
                                    borderRadius: '10px',
                                    background: '#51cbce',
                                    mt: 3,
                                    '&:hover': {
                                          background: '#51cbce'
                                    }
                              }}
                              onClick={() => openAddEquipmentDrawer()}
                        >
                              Save
                        </LoadingButton>
                  </Box>
                  {
                        isAddEquipmentOwnerModalOpen && <EquipmentOwnerModal />
                  }
            </>
      )
}