import { Box, Card, CircularProgress, Container, Grid, Stack, Typography } from "@mui/material";
import Page from "../../../../../components/Page";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { authActions } from "../../../../../redux";
import { useEffect } from "react";



export default function GoogleSignIn() {

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const token = pathname.split('/')[4];

  const handleSignIn = async() => {
    await dispatch(authActions.googleSignin(token));
  }

  useEffect(() => {
    setTimeout(() => {
      handleSignIn();
    }, 2000);
  }, []);

  return (
    <Page title="Sign-in">
      <Container maxWidth="sm">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '100vh' }}
        >
          <Grid item xs={3}>
            <Box
              component={Card}
              width={400}
              height={'100%'}
              paddingX={5}
              paddingY={5}
              sx={{ borderRadius: '12px' }}
            >
              <Stack
                direction={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                spacing={2}
              >
                <CircularProgress />
                <Typography variant="subtitle1">Signing in...</Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}