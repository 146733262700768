import { equipmentTypes } from "./equipment.types";


export const equipmentInitialState = {
      isFetchingProjectList: false,
      isFetchingRentalOwners: false,
      isFetchingEquipmentModels: false,
      
      isAddEquipmentDrawerOpen: false,
      isEditEquipmentDrawerOpen: false,
      
      reloadRentalOwnerEquipmentsTable: false,

      equipmentInfo: {}
}

export const equipment = (state = equipmentInitialState, action) => {
      switch (action.type) {
            case equipmentTypes.IS_ADD_EQUIPMENT_DRAWER_OPEN:
                  return {
                        ...state,
                        isAddEquipmentDrawerOpen: !state.isAddEquipmentDrawerOpen,
                        isFetchingEquipmentModels: !state.isFetchingEquipmentModels,
                        isFetchingRentalOwners: !state.isFetchingRentalOwners,
                        isFetchingProjectList: !state.isFetchingProjectList
                  };
            case equipmentTypes.IS_EDIT_EQUIPMENT_DRAWER_OPEN:
                  return {
                        ...state,
                        isEditEquipmentDrawerOpen: !state.isEditEquipmentDrawerOpen,
                        isFetchingEquipmentModels: !state.isFetchingEquipmentModels,
                        isFetchingRentalOwners: !state.isFetchingRentalOwners,
                        isFetchingProjectList: !state.isFetchingProjectList
                  };
            case equipmentTypes.RELOAD_RENTAL_OWNERS_EQUIPMENT_TABLE:
                  return {
                        ...state,
                        reloadRentalOwnerEquipmentsTable: !state.reloadRentalOwnerEquipmentsTable
                  }
            default:
                  return state;
      }
}