import axios from "axios";
import { getToken } from "./auth";
import { authActions, store } from '../redux'

const API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
      baseURL: API_URL,
});

const axiosNoAuthInstance = axios.create({
      baseURL: API_URL,
});

axiosInstance.interceptors.request.use((config) => {
      const token = getToken();
      if (token) {
            config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
});

axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
            if (error.response.status === 401) {
                  return await store.dispatch(authActions.clearAuthIfTokenIsNotValid())
            } else {
                  return Promise.reject(
                        (error.response && error.response.data) || "Something went wrong"
                  );
            }
      }
);

axiosNoAuthInstance.interceptors.response.use(
      (response) => response,
      (error) => {
            return Promise.reject(
                  (error.response && error.response.data) || "Something went wrong"
            );
      }
);

export { axiosInstance, axiosNoAuthInstance, API_URL };
