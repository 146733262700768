import { Box, Button, Card } from "@mui/material";
import ProjectsTable from "./components/table";
import AutoComplete from "../../../components/Autocomplete";
import { projectStatus } from "../../../statics";
import AddIcon from '@mui/icons-material/Add';
import AddProject from "./components/drawerItems/AddProject";
import DrawerComponent from "../../../components/DrawerComponent";
import useProject from "../../../hooks/useProject";
import EditProject from "./components/drawerItems/EditProject";
import Page from "../../../components/Page";

export default function Projects() {

      const {
            isAddProject,
            isEditProject,
            openAddProjectDrawer,
            openEditProjectDrawer
      } = useProject();

      return (
            <Page title="Projects">
                  <Box
                        component={Card}
                        sx={{
                              borderRadius: '12px',
                              py: 2,
                              px: 2
                        }}
                  >
                        <Box
                              sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mx: 2,
                                    my: 3
                              }}
                        >
                              <AutoComplete
                                    placeholder="Filter Projects"
                                    listItem={projectStatus}
                                    sx={{
                                          width: '30vh'
                                    }}
                                    size="small"
                              />

                              <Button
                                    startIcon={<AddIcon />}
                                    variant="contained"
                                    disableRipple
                                    disableTouchRipple
                                    onClick={() => openAddProjectDrawer()}
                                    sx={{
                                          borderRadius: '10px',
                                          textTransform: 'none',
                                          background: '#51cbce',
                                          '&:hover': {
                                                background: '#51cbce'
                                          }
                                    }}
                              >
                                    Add Project
                              </Button>

                              <DrawerComponent
                                    drawerLabel={'Add Project'}
                                    onOpen={isAddProject}
                                    width="70vh"
                                    onClose={() => openAddProjectDrawer()}
                              >
                                    <AddProject />
                              </DrawerComponent>

                              <DrawerComponent
                                    drawerLabel={'Edit Project'}
                                    onOpen={isEditProject}
                                    width="70vh"
                                    onClose={() => openEditProjectDrawer()}
                              >
                                    <EditProject />
                              </DrawerComponent>
                        </Box>

                        <ProjectsTable />
                  </Box>
            </Page>
      )
}