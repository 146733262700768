import { Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"

export default function ContractorProjectsTable() {

      return (
            <TableContainer sx={{ minWidth: '50vh', maxHeight: '50vh' }}>
                  <Table stickyHeader>
                        <TableHead>
                              <TableRow>
                                    {
                                          tableHeader.map((row) => (
                                                <TableCell align="left" key={row.id}>
                                                      <Typography variant="subtitle2" color={'#51cbce'} fontWeight={700}>{row.label}</Typography>
                                                </TableCell>
                                          ))
                                    }
                              </TableRow>
                        </TableHead>
                        <TableBody>
                              {
                                    tableRow.map(row => (
                                          <TableRow
                                                key={row.id}
                                                hover
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                          >

                                                <TableCell align="left">
                                                      {row.title}
                                                </TableCell>
                                                <TableCell align="left">{row.location}</TableCell>
                                                <TableCell align="left">{row.budget}</TableCell>
                                                <TableCell align="left">{row.start_date}</TableCell>
                                                <TableCell align="left">{row.duration}</TableCell>
                                                <TableCell align="left">
                                                      {
                                                            (row.status === 'on_going')
                                                                  ? <Chip size="small" label="On Going" variant="outlined" sx={{ backgroundColor: '#fbc658', fontWeight: 700 }} />
                                                                  : <Chip size="small" label="Completed" variant="outlined" sx={{ backgroundColor: '#6bd098', fontWeight: 700 }} />
                                                      }
                                                </TableCell>
                                          </TableRow>
                                    ))
                              }
                        </TableBody>
                  </Table>
            </TableContainer>
      )
}

const tableRow = [
      {
            id: 1,
            title: 'Construction of 2 classrooms',
            location: 'Tupi, South Cotabato',
            budget: '2,500,000',
            start_date: 'Jan 15, 2023',
            duration: '160 CD',
            status: 'on_going'
      }
];

const tableHeader = [
      { id: 1, label: 'TITLE' },
      { id: 2, label: 'LOCATION' },
      { id: 3, label: 'BUDGET' },
      { id: 4, label: 'START DATE' },
      { id: 5, label: 'DURATION' },
      { id: 6, label: 'STATUS' }
]