import { createContext, useCallback, useEffect } from "react";
import { authActions, authInitialState } from "../redux";
import { useDispatch, useSelector } from "react-redux";

const AuthContext = createContext({
      ...authInitialState,
      login: () => Promise.resolve(),
      logout: () => Promise.resolve()
});


const AuthProvider = ({ children }) => {

      const dispatch = useDispatch();

      const state = useSelector((state) => state.authentication);

      const login = async (username, password) => {
            await dispatch(authActions.login({ username, password }));
      }

      const logout = async () => {
            await dispatch(authActions.logout());
      }

      const checkTokenExpiry = useCallback(() => {
            try {
                  setTimeout(function run(){
                        dispatch(authActions.validateToken());
                        setTimeout(run, 60000)
                  }, 60000)
            } catch (error) {
                  // console.log(error);
            }
      }, []);

      useEffect(() => {
            dispatch(authActions.verifyAuth());
      }, []);

      useEffect(() => {
            checkTokenExpiry();
      }, []);

      return (
            <AuthContext.Provider
                  value={{
                        ...state,
                        login,
                        logout
                  }}
            >
                  {children}
            </AuthContext.Provider>
      );
};

export { AuthContext, AuthProvider };