import PropTypes from "prop-types";
import { Box, Divider, Drawer, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

DrawerComponent.propTypes = {
  children: PropTypes.node,
};

export default function DrawerComponent({
  children,
  drawerLabel,
  onClose,
  onOpen,
  width = "80vh",
}) {
  return (
    <Drawer anchor={"right"} open={onOpen} onClose={onClose}>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        marginBottom={2}
        paddingTop={2}
        paddingLeft={3}
        paddingRight={4}
      >
        <Typography variant="h5" color={"#51cbce"} sx={{ fontWeight: "600" }}>
          {drawerLabel}
        </Typography>

        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Divider />

      <Box width={width}>{children}</Box>
    </Drawer>
  );
}
