export const ADMIN_PATHS_ALLOWED = [
   "/admin/equipments",
   "/admin/projects",
   "/admin/equipment-request",
   "/admin/de-mobilization",
   "/admin/payouts"
];

export const CONTRACTOR_PATHS_ALLOWED = [
   "/contractor/timekeeping",
   "/contractor/projects",
   "/contractor/billing"
];

export const RENTAL_OWNER_PATHS_ALLOWED = [
   "/rental-owner/overview",
   "/rental-owner/equipments",
   "/rental-owner/payouts"
]