const breakpoints = {
   values: {
       xs: 0,
       w460: 460,
       sm: 600,
       // md: 900,
       md: 768,
       lg: 1400,
       lg1: 992,
       xl: 1536,
       xl1: 1200,
       xl2: 1400,
   },
};

export default breakpoints;