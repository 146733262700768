import { Box, Button, Card, Stack, Typography } from "@mui/material";
import Page from "../../../components/Page";
import RentalOwnerOverviewCards from './components/cards';
import RentalOwnerEquipmentsTable from './components/table';
import AutoComplete from "../../../components/Autocomplete";
import { useEffect, useState } from "react";
import { fetchEquipments } from "../../../api/rental-owners";
import useEquipment from "../../../hooks/useEquipment";
import { getAuthUser } from "../../../utils/auth";
import Swal from "sweetalert2";
import Map from "../../../components/map";

export default function RentalOwnerOverview() {

  const {
    reloadRentalOwnerEquipmentsTable
  } = useEquipment();

  const [viewType, setViewType] = useState('list');

  const [equipmentList, setEquipmentList] = useState();

  const user = getAuthUser();

  const [isMounted, setIsMounted] = useState(false);

  const handleFetchEquipments = async () => {
    await fetchEquipments(user?.company_id).then(response => {

      setEquipmentList(response);

    }).catch(error => {
      Swal.fire({
        icon: 'error',
        text: 'Unable to fetch equipments'
      });
      setEquipmentList([])
    });
  }

  useEffect(() => {
    handleFetchEquipments();
  }, [reloadRentalOwnerEquipmentsTable])

  useEffect(() => {
    if (!isMounted) {
      handleFetchEquipments()
      setIsMounted(true)
    }
  }, [])

  return (
    <Page title="Overview">

      <RentalOwnerOverviewCards />

      <Box
        component={Card}
        borderRadius={'12px'}
        paddingX={2}
        paddingY={2}
      >

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            my: 1
          }}
        >
          <Typography variant="h5">Equipments</Typography>


          <Stack
            direction={'row'}
            justifyContent={'space-between'}
          >
            <Stack
              direction={'row'}
              spacing={1}
              alignItems={'center'}
              sx={{
                my: 3
              }}
            >
              <Button
                variant={(viewType === 'list') ? 'contained' : 'outlined'}
                size="small"
                sx={{
                  color: (viewType === 'list') ? 'white' : '#51cbce'
                }}
                onClick={() => setViewType('list')}
              >
                List
              </Button>

              <Button
                variant={(viewType === 'map') ? 'contained' : 'outlined'}
                size="small"
                sx={{
                  color: (viewType === 'map') ? 'white' : '#51cbce'
                }}
                onClick={() => setViewType('map')}
              >
                Map
              </Button>
            </Stack>

            <Stack
              minWidth={300}
            >
              <AutoComplete
                isMultiple={true}
                placeholder="Select..."
                size="small"
              />
            </Stack>
          </Stack>
          {
            (viewType === 'list')
              ? <RentalOwnerEquipmentsTable equipmentList={equipmentList} />
              : <Map equipments={equipmentList} />
          }

        </Box>
      </Box>
    </Page>
  )
}