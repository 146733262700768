import { getToken } from '../../utils/auth';
import { axiosNoAuthInstance as axios } from '../../utils/axios';
import { axiosInstance as api } from '../../utils/axios';

export const login = async ({ username, password }) => {

      const payload = {
            username,
            password
      };

      const response = await axios.post('/auth/login', payload);
      return response.data;
}


export const register = async (payload) => {
      const response = await axios.post('/auth/register', payload);
      return response.data;
}


export const googleAccountRegistration = async (token, userType) => {
      const payload = {
            token: token,
            user_type: userType
      };

      const response = await axios.post('/auth/google-account-registration', payload);
      return response.data;
}

export const googleAccountSignIn = async(token) => {
      const response = await axios.post('/auth/google-account-sign-in', { token: token });
      return response.data;
}


export const facebookAccountRegistration = async (token, userType) => {
      const payload = {
            token: token,
            user_type: userType
      };

      const response = await axios.post('/auth/facebook-account-registration', payload);
      return response.data;
}

export const facebookAccountSignIn = async(token) => {
      const response = await axios.post('/auth/facebook-account-sign-in', { token: token });
      return response.data;
}


export const logout = async () => {
      const response = await api.post('/auth/logout');
      return response.data;
}