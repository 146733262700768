import { projectTypes } from './project.types'

export const projectInitialState = { 
      isEditProject: false,
      isAddProject: false,
      projectInfo: {}
}


export const project = (state = projectInitialState, action) => {
      switch (action.type) {
            case projectTypes.IS_EDIT_PROJECT:
                  return {
                        ...state,
                        isEditProject: !state.isEditProject,
                        projectInfo: action.payload
                  };
            case projectTypes.IS_ADD_PROJECT:
                  return {
                        ...state,
                        isAddProject: !state.isAddProject
                  };
            default:
                  return state;
      }
}