import { Box, Button, Card, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import AutoComplete from "../../../components/Autocomplete";
import { equipmentSize, equipmentType, equipmentAvailability, equipmentOwners } from "../../../statics";
import EquipmentCards from "./components/cards";
import EquipmentTable from "./components/table";
import AddIcon from '@mui/icons-material/Add';
import DrawerComponent from "../../../components/DrawerComponent";
import useEquipment from "../../../hooks/useEquipment";
import AddEquipmentDrawer from "./components/drawerItems/addEquipmentDrawer";
import EditEquipmentDrawer from "./components/drawerItems/editEquipmentDrawer";
import { useDispatch } from "react-redux";
import Page from "../../../components/Page";
import Map from "../../../components/map";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { fetchAllEquipments } from "../../../api/admin";

export default function Equipments() {

      const dispatch = useDispatch();

      const [viewType, setViewType] = useState('list');

      const {
            isAddEquipmentDrawerOpen,
            isEditEquipmentDrawerOpen,
            openAddEquipmentDrawer,
            openEditEquipmentDrawer
      } = useEquipment();

      const [equipmentList, setEquipmentList] = useState();

      const [isMounted, setIsMounted] = useState(false);

      const handleFetchEquipments = async () => {
            await fetchAllEquipments().then(response => {

                  setEquipmentList(response);

            }).catch(error => {
                  Swal.fire({
                        icon: 'error',
                        text: 'Unable to fetch equipments'
                  });
                  setEquipmentList([])
            });
      }

      useEffect(() => {
            if (!isMounted) {
                  handleFetchEquipments()
                  setIsMounted(true)
            }
      }, [])

      return (
            <Page title="Equipments">
                  <Box
                        display={'flex'}
                        paddingY={3}
                  >
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                              <Grid item xs={2} sm={2} md={3} key={1}>
                                    <Stack
                                          direction={'column'}
                                          spacing={2}
                                    >
                                          <Typography variant="subtitle2">Equipment Type</Typography>
                                          <AutoComplete isMultiple={true} placeholder="Select..." listItem={equipmentType} size="small" />
                                    </Stack>
                              </Grid>
                              <Grid item xs={2} sm={2} md={3} key={2}>
                                    <Stack
                                          direction={'column'}
                                          spacing={2}
                                    >
                                          <Typography variant="subtitle2">Equipment Size</Typography>
                                          <AutoComplete isMultiple={true} placeholder="Select..." listItem={equipmentSize} size="small" />
                                    </Stack>
                              </Grid>
                              <Grid item xs={2} sm={2} md={3} key={3}>
                                    <Stack
                                          direction={'column'}
                                          spacing={2}
                                    >
                                          <Typography variant="subtitle2">Current Location</Typography>
                                          <AutoComplete placeholder="Select..." listItem={equipmentSize} size="small" />
                                    </Stack>
                              </Grid>
                              <Grid item xs={2} sm={2} md={3} key={4}>
                                    <Stack
                                          direction={'column'}
                                          spacing={2}
                                    >
                                          <Typography variant="subtitle2">Availability</Typography>
                                          <AutoComplete placeholder="Select..." listItem={equipmentAvailability} size="small" />
                                    </Stack>
                              </Grid>
                        </Grid>
                  </Box>

                  <Divider sx={{ mt: 3 }} />

                  <EquipmentCards />

                  <Box
                        component={Card}
                        borderRadius={'12px'}
                        paddingX={2}
                        paddingY={2}
                  >

                        <Box
                              sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    my: 1
                              }}
                        >
                              <Typography variant="h5">Equipments</Typography>

                              {/* Reserve for analytics
                              <Button
                                    startIcon={<AddIcon />}
                                    variant="contained"
                                    disableRipple
                                    disableTouchRipple
                                    onClick={() => openAddEquipmentDrawer()}
                                    sx={{
                                          borderRadius: '10px',
                                          textTransform: 'none',
                                          background: '#51cbce',
                                          '&:hover': {
                                                background: '#51cbce'
                                          }
                                    }}
                              >
                                    Add Equipment
                              </Button> */}


                        </Box>

                        <Stack
                              direction={'row'}
                              justifyContent={'space-between'}
                              alignItems={'center'}
                        >
                              <Stack
                                    direction={'row'}
                                    spacing={1}
                                    alignItems={'center'}
                                    sx={{
                                          my: 3
                                    }}
                              >
                                    <Button
                                          variant={(viewType === 'list') ? 'contained' : 'outlined'}
                                          size="small"
                                          sx={{
                                                color: (viewType === 'list') ? 'white' : '#51cbce'
                                          }}
                                          onClick={() => setViewType('list')}
                                    >
                                          List
                                    </Button>

                                    <Button
                                          variant={(viewType === 'map') ? 'contained' : 'outlined'}
                                          size="small"
                                          sx={{
                                                color: (viewType === 'map') ? 'white' : '#51cbce'
                                          }}
                                          onClick={() => setViewType('map')}
                                    >
                                          Map
                                    </Button>
                              </Stack>

                              <Stack
                                    minWidth={300}
                              >
                                    <AutoComplete
                                          isMultiple={true}
                                          placeholder="Select..."
                                          size="small"
                                          listItem={equipmentOwners}
                                    />
                              </Stack>
                        </Stack>

                        {
                              (viewType === 'list')
                                    ? <EquipmentTable equipmentList={equipmentList}/>
                                    : <Map equipments={equipmentList}/>
                        }
                  </Box>


                  <DrawerComponent
                        drawerLabel={'Add Equipment'}
                        onOpen={isAddEquipmentDrawerOpen}
                        width="90vh"
                        onClose={() => openAddEquipmentDrawer()}
                  >
                        <AddEquipmentDrawer />
                  </DrawerComponent>


                  <DrawerComponent
                        drawerLabel={'Edit Equipment'}
                        onOpen={isEditEquipmentDrawerOpen}
                        onClose={() => openEditEquipmentDrawer()}
                  >
                        <EditEquipmentDrawer />
                  </DrawerComponent>
            </Page>
      )
}