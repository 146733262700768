import AdminLayout from "../layouts/admin-layout";
import { useRoutes, Navigate } from 'react-router';

import Equipments from "../pages/admin/equipments";
import Projects from "../pages/admin/projects";
import EquipmentRequest from "../pages/admin/equipment-request";
import DeMobilization from "../pages/admin/de-mobilization";
import Payouts from "../pages/admin/payouts";

import Register from "../pages/authentication/register";
import Login from "../pages/authentication/login";

import TimeKeeping from "../pages/contractor/timekeeping";
import ContratorProjects from "../pages/contractor/projects";
import Billing from "../pages/contractor/billing";

import RentalOwnerOverview from '../pages/rental_owner/overview';
import RentalOwnerEquipment from '../pages/rental_owner/equipments';
import RentalOwnerPayout from '../pages/rental_owner/payouts';

import AuthLayout from "../layouts/auth-layout";
import ContractorLayout from '../layouts/contractor-layout';
import RentalOwnerLayout from '../layouts/rental-owner-layout';

import GuestGuard from "../guards/guest-guard";
import AuthGuard from "../guards/auth-guard";

import { AuthProvider } from "../context/auth-context";
import { GlobalProvider } from "../context/global-context";
import { GoogleAccountRegistration, GoogleSignIn, GoogleSignInFailed } from "../pages/authentication/login/sections/google-redirect";
import { FacebookAccountRegistration, FacebookSignInFailed, FacebookSignIn } from "../pages/authentication/login/sections/facebook-redirect";
import { PATH_AUTH } from "./paths";


const Routes = () => {

      return useRoutes([
            {
                  path: 'auth',
                  element: (
                        <AuthProvider>
                              <GuestGuard>
                                    <AuthLayout />
                              </GuestGuard>
                        </AuthProvider>
                  ),
                  children: [
                        {
                              element: <Navigate to={'/auth/login'} />,
                              index: true,
                        },
                        {
                              path: 'login',
                              element: <Login />
                        },
                        {
                              path: 'register',
                              element: <Register />
                        },
                        {
                              path: 'google-login',
                              children: [
                                    {
                                          path: 'registration/:token',
                                          element: <GoogleAccountRegistration />
                                    },
                                    {
                                          path: 'success/:token',
                                          element: <GoogleSignIn />
                                    },
                                    {
                                          paht: 'failed/:token',
                                          element: <GoogleSignInFailed />
                                    }
                              ]
                        },
                        {
                              path: 'facebook-login',
                              children: [
                                    {
                                          path: 'registration/:token',
                                          element: <FacebookAccountRegistration />
                                    },
                                    {
                                          path: 'success/:token',
                                          element: <FacebookSignIn />
                                    },
                                    {
                                          path: 'failed/:token',
                                          element: <FacebookSignInFailed />
                                    }
                              ]
                        },
                        { path: "*", element: <Navigate to="/auth/login" replace /> }
                  ]
            },
            {
                  path: 'admin',
                  element: (
                        <AuthProvider>
                              <AuthGuard>
                                    <GlobalProvider>
                                          <AdminLayout />
                                    </GlobalProvider>
                              </AuthGuard>
                        </AuthProvider>
                  ),
                  children: [
                        {
                              element: <Navigate to={'/admin/equipments'} />,
                              index: true,
                        },
                        {
                              path: 'equipments',
                              element: <Equipments />
                        },
                        {
                              path: 'projects',
                              element: <Projects />
                        },
                        {
                              path: 'equipment-request',
                              element: <EquipmentRequest />
                        },
                        {
                              path: 'de-mobilization',
                              element: <DeMobilization />
                        },
                        {
                              path: 'payouts',
                              element: <Payouts />
                        },
                        { path: "*", element: <Navigate to="/admin/equipments" replace /> }
                  ]
            },
            {
                  path: 'contractor',
                  element: (
                        <AuthProvider>
                              <AuthGuard>
                                    <GlobalProvider>
                                          <ContractorLayout />
                                    </GlobalProvider>
                              </AuthGuard>
                        </AuthProvider>
                  ),
                  children: [
                        {
                              element: <Navigate to={'/contractor/timekeeping'} />,
                              index: true,
                        },
                        {
                              path: 'timekeeping',
                              element: <TimeKeeping />
                        },
                        {
                              path: 'projects',
                              element: <ContratorProjects />
                        },
                        {
                              path: 'billing',
                              element: <Billing />
                        },
                        { path: "*", element: <Navigate to="/contractor/equipments" replace /> }
                  ]
            },
            {
                  path: 'rental-owner',
                  element: (
                        <AuthProvider>
                              <AuthGuard>
                                    <GlobalProvider>
                                          <RentalOwnerLayout />
                                    </GlobalProvider>
                              </AuthGuard>
                        </AuthProvider>
                  ),
                  children: [
                        {
                              element: <Navigate to={'/rental-owner/overview'} />,
                              index: true
                        },
                        {
                              path:'overview',
                              element: <RentalOwnerOverview />
                        },
                        {
                              path: 'equipments',
                              element: <RentalOwnerEquipment />
                        },
                        {
                              path: 'payouts',
                              element: <RentalOwnerPayout />
                        }
                  ]
            },
            { path: "*", element: <Navigate to="/auth/login" replace /> }
      ])
}

export default Routes;