import { equipmentTypes } from './equipment.types';


export const equipmentActions = {
      addEquipmentDrawer,
      editEquipmentDrawer,
      reloadRentalOwnerEquipmentsTable
};


function addEquipmentDrawer() {
      return dispatch => {
            dispatch({
                  type: equipmentTypes.IS_ADD_EQUIPMENT_DRAWER_OPEN
            });
      };
};

function editEquipmentDrawer() {
      return dispatch => {
            dispatch({
                  type: equipmentTypes.IS_EDIT_EQUIPMENT_DRAWER_OPEN
            });
      };
};

function reloadRentalOwnerEquipmentsTable() {
      return dispatch => {
            dispatch({
                  type: equipmentTypes.RELOAD_RENTAL_OWNERS_EQUIPMENT_TABLE
            });
      }
}