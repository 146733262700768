import { Box, Button, Card, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import AutoComplete from "../../../components/Autocomplete";
import { equipmentType } from "../../../statics";
import ContractorTimeKeepingCards from "./components/cards";
import ContractorTimeKeepingTable from "./components/table";
import Page from "../../../components/Page";
import { useState } from "react";
import Map from "../../../components/map";


export default function TimeKeeping() {

      const [viewType, setViewType] = useState('list');

      const [acquiredEquipments, setAcquiredEquipments] = useState([]);

      return (
            <Page title="Timekeeping">
                  <Box
                        display={'flex'}
                        paddingY={3}
                  >
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                              <Grid item xs={2} sm={2} md={3} key={1}>
                                    <Stack
                                          direction={'column'}
                                          spacing={2}
                                    >
                                          <Typography variant="subtitle2">Equipment Type</Typography>
                                          <AutoComplete isMultiple={true} placeholder="Select..." listItem={equipmentType} size="small" />
                                    </Stack>
                              </Grid>
                              <Grid item xs={2} sm={2} md={3} key={2}>
                                    <Stack
                                          direction={'column'}
                                          spacing={2}
                                    >
                                          <Typography variant="subtitle2">Equipment Size</Typography>
                                          <AutoComplete isMultiple={true} placeholder="Select..." listItem={equipmentType} size="small" />
                                    </Stack>
                              </Grid>
                        </Grid>
                  </Box>

                  <Divider sx={{ mt: 3 }} />

                  <ContractorTimeKeepingCards />

                  <Box
                        component={Card}
                        borderRadius={'12px'}
                        paddingX={2}
                        paddingY={2}
                  >

                        <Box
                              sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    my: 1
                              }}
                        >
                              <Typography variant="h5">Equipments</Typography>


                              <Stack
                                    direction={'row'}
                                    justifyContent={'space-between'}
                              >
                                    <Stack
                                          direction={'row'}
                                          spacing={1}
                                          alignItems={'center'}
                                          sx={{
                                                my: 3
                                          }}
                                    >
                                          <Button
                                                variant={(viewType === 'list') ? 'contained' : 'outlined'}
                                                size="small"
                                                sx={{
                                                      color: (viewType === 'list') ? 'white' : '#51cbce'
                                                }}
                                                onClick={() => setViewType('list')}
                                          >
                                                List
                                          </Button>

                                          <Button
                                                variant={(viewType === 'map') ? 'contained' : 'outlined'}
                                                size="small"
                                                sx={{
                                                      color: (viewType === 'map') ? 'white' : '#51cbce'
                                                }}
                                                onClick={() => setViewType('map')}
                                          >
                                                Map
                                          </Button>
                                    </Stack>

                                    <Stack
                                          minWidth={300}
                                    >
                                          <AutoComplete
                                                isMultiple={true}
                                                placeholder="Select..."
                                                size="small"
                                          />
                                    </Stack>
                              </Stack>
                              {
                                    (viewType === 'list')
                                          ? <ContractorTimeKeepingTable />
                                          : <Map />
                              }

                        </Box>


                  </Box>
            </Page>
      )
}