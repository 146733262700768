import { Autocomplete, Button, TextField } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function AutoComplete({ listItem = [], isMultiple = false, label, PaperComponent, ...others }) {

      return (
            <Autocomplete
                  disablePortal
                  options={listItem}
                  sx={{
                        minWidth: '8vh',
                        backgroundColor: 'white'
                  }}
                  multiple={isMultiple}
                  clearIcon={<HighlightOffIcon />}
                  freeSolo
                  ListboxProps={{ style: { maxHeight: 100 } }}
                  renderOption={(props, option) => {
                        return (
                              <li
                                    {...props}
                                    key={option.id}
                                    style={{
                                          background: 'none'
                                    }}
                              >
                                    {option.label}
                              </li>
                        );
                  }}
                  isOptionEqualToValue={(option, value) =>
                        option?.id === value?.id
                  }
                  onKeyPress={(e) => { e.preventDefault(); }}
                  PaperComponent={PaperComponent}
                  {...others}
                  renderInput={(fields) =>
                        <TextField
                              fullWidth
                              label={label}
                              {...fields}
                              {...others}
                        />
                  }
            />
      )
}