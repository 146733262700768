import { Box, Card } from "@mui/material";
import EquipmentRequestTable from "./table";
import AutoComplete from "../../../components/Autocomplete";
import Page from "../../../components/Page";

export default function EquipmentRequest() {



      return (
            <Page title="Equipment Request">
                  <Box
                        component={Card}
                        sx={{
                              borderRadius: '12px',
                              py: 2,
                              px: 2
                        }}
                  >
                        <div style={{ marginBottom: '20px', marginLeft: '10px' }}>
                              <AutoComplete
                                    placeholder="Select..."
                                    size="small"
                                    listItem={equipmentRequestStatus}
                                    sx={{
                                          width: '30vh'
                                    }}
                              />
                        </div>

                        <EquipmentRequestTable />
                  </Box>
            </Page>
      )
}

const equipmentRequestStatus = [
      { value: 1, label: 'Pending' },
      { value: 2, label: 'In Progress' },
      { value: 3, label: 'Pending Mobilization' },
      { value: 4, label: 'Completed' },
      { value: 5, label: 'Contractor Cancelled' }
]