import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Stack, TextField, Tooltip, Typography } from "@mui/material";
import AutoComplete from "../../../../../components/Autocomplete";
import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import useGlobal from "../../../../../hooks/useGlobal";
import useProject from "../../../../../hooks/useProject";
import moment from "moment/moment";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { projectActions } from "../../../../../redux";
import AddContractorModal from "../modals/addContractorModal";
import AddIcon from '@mui/icons-material/Add';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import {
      FormProvider,
      FormTextField,
      FormDatePicker
} from '../../../../../components/hook-form'
import Swal from "sweetalert2";
import NumericFormatCustom from "../../../../../components/NumericFormat";

export default function EditProject() {

      const dispatch = useDispatch();

      const {
            fetchContractors
      } = useGlobal();

      const {
            isEditProject,
            isAddContractorModalOpen,
            projectInfo,
            updateProjectInfo
      } = useProject();

      const EditProjectSchema = Yup.object().shape({
            name: Yup.string().required('Project name is required'),
            billing_start_date: Yup.date().required('Billing start date is required'),
            location: Yup.string().required('Location is required'),
            billing_scheme: Yup.string().required('Billing scheme is required')
      })

      const defaultValues = {
            name: projectInfo.name,
            budget: projectInfo.budget,
            contract_duration: projectInfo.contract_duration,
            location: projectInfo.location,
            billing_start_date: dayjs(moment(projectInfo.billing_start_date).format("yyyy-MM-DD")),
            billing_scheme: projectInfo.billing_scheme
      }

      const methods = useForm({
            resolver: yupResolver(EditProjectSchema),
            defaultValues
      })

      const {
            setValue,
            handleSubmit,
            formState: { error, isSubmitting }
      } = methods;

      const [contractorList, setContractorList] = useState([])

      const statusList = [
            { id: 'in_progress', label: 'In Progress', value: 'in_progress' },
            { id: 'completed', label: 'Completed', value: 'completed' }
      ]

      const handleSelectContractor = (e, v) => {
            setValue('contractor_id', v?.id ?? null)
      }

      const handleStartDateChange = (e) => {
            setValue('start_date', moment(e.$d).format("yyyy-MM-DD"))
      }

      const handleStatusChanged = (e, v) => {
            setValue('status', v?.id ?? null)
      }

      const loadContractors = async () => {
            await fetchContractors().then(response => {
                  setContractorList(response);
            }).catch(() => {
                  console.log('Something went wrong')
            })
      }

      const onSubmit = async (data) => {
            const payload = {
                  ...data,
                  billing_start_date: moment(data.billing_start_date).format("yyyy-MM-DD")
            }

            Swal.fire({
                  title: "Are you sure you want to update this project",
                  text: "",
                  reverseButtons: true,
                  showCancelButton: true,
                  confirmButtonColor: "#51cbce",
                  cancelButtonColor: "#919EAB",
                  confirmButtonText: "Update",
            }).then(async (result) => {
                  if (!result.isConfirmed) {
                        return;
                  } else {
                        await updateProjectInfo(projectInfo.id, payload);
                  }
            });

      }

      useEffect(() => {
            if (isEditProject) {
                  loadContractors();
            }
      }, [isEditProject])

      return (

            <Box
                  marginTop={1}
                  paddingX={5}
                  paddingY={2}
            >
                  <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Stack
                              direction={'column'}
                              spacing={3}
                        >
                              <Typography variant="h6">Project Information</Typography>

                              <FormTextField
                                    name={'name'}
                                    label="Project Name"
                                    placeholder="Enter project name"
                                    variant="outlined"
                              />

                              <Stack
                                    direction={'row'}
                                    spacing={1}
                              >
                                    <AutoComplete
                                          label={'Contractor'}
                                          fullWidth
                                          placeholder="Contractor"
                                          listItem={contractorList}
                                          value={projectInfo?.contractor?.company_name ?? ""}
                                          onChange={(e, v) => handleSelectContractor(e, v)}
                                          PaperComponent={({ children }) => {
                                                return (
                                                      <Paper>
                                                            {children}
                                                            <Button
                                                                  fullWidth
                                                                  variant="contained"
                                                                  sx={{
                                                                        justifyContent: "center",
                                                                        background: '#51cbce',
                                                                        '&:hover': {
                                                                              background: '#51cbce'
                                                                        }
                                                                  }}
                                                                  onMouseDown={() => dispatch(projectActions.openAddContractorModal())}
                                                            >
                                                                  <AddIcon /> Add Contractor
                                                            </Button>
                                                      </Paper>
                                                );
                                          }}
                                    />
                              </Stack>

                              <FormTextField
                                    name={'budget'}
                                    label="Budget"
                                    placeholder="Budget"
                                    InputProps={{
                                          inputComponent: NumericFormatCustom
                                    }}
                              />

                              <Stack
                                    direction={'row'}
                                    spacing={2}
                              >
                                    <DatePicker
                                          label={`Start Date`}
                                          disablePast
                                          onChange={(e) => handleStartDateChange(e)}
                                          slotProps={{
                                                textField: {
                                                      size: "medium",
                                                      readOnly: true,
                                                      fullWidth: true
                                                }
                                          }}
                                    />

                                    <FormTextField
                                          label="Contract Duration"
                                          placeholder="Contract Duration"
                                          fullWidth
                                          variant="outlined"
                                          type="number"
                                          name="contract_duration"
                                    />
                              </Stack>

                              <FormTextField
                                    name="location"
                                    label="Location"
                                    variant="outlined"
                              />

                              <AutoComplete
                                    label={'Status'}
                                    fullWidth
                                    placeholder="Status"
                                    listItem={statusList}
                                    value={(projectInfo.status === 'in_progress') ? 'In Progress' : 'Completed'}
                                    onChange={(e, v) => handleStatusChanged(e, v)}
                              />

                              <FormDatePicker
                                    name="billing_start_date"
                                    label={`Billing Start Date`}
                                    disablePast
                                    slotProps={{
                                          textField: {
                                                size: "medium",
                                                readOnly: true,
                                                fullWidth: true
                                          }
                                    }}
                              />

                              <FormTextField
                                    name="billing_scheme"
                                    label="Billing Scheme"
                                    variant="outlined"
                              />

                              <Stack
                                    justifyContent={'flex-end'}
                                    direction={'row'}
                                    spacing={2}
                              >
                                    <LoadingButton
                                          type="submit"
                                          variant="contained"
                                          disableRipple
                                          fullWidth
                                          loading={isSubmitting}
                                          sx={{
                                                borderRadius: '10px',
                                                background: '#51cbce',
                                                '&:hover': {
                                                      background: '#51cbce'
                                                }
                                          }}
                                    >
                                          Update
                                    </LoadingButton>
                              </Stack>
                        </Stack>
                        {
                              isAddContractorModalOpen && <AddContractorModal />
                        }
                  </FormProvider>
            </Box>
      )
}