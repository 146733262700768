import Typography from "./typography";
import CssBaseline from './css-baseline';
import Card from "./card";
import Button from "./button";
import Table from "./table";
import Menu from "./menu";
import Dialog from "./dialog";
import Input from "./input";
import Backdrop from "./backdrop";

export default function ComponentOverrides(theme) {
   return Object.assign(
      Typography(theme),
      Card(theme),
      Table(theme),
      Menu(theme),
      Dialog(theme),
      Button(theme),
      Input(theme),
      Backdrop(theme),
      CssBaseline(),
   )
}