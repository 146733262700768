import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";
import { PATH_AUTH } from "../routes/paths";
import { ADMIN_PATHS_ALLOWED, CONTRACTOR_PATHS_ALLOWED, RENTAL_OWNER_PATHS_ALLOWED } from "../config";

AuthGuard.propTypes = {
      children: PropTypes.node
}

export default function AuthGuard({ children }) {

      const { isAuthenticated, authData } = useAuth();

      const { pathname } = useLocation();

      if (!isAuthenticated) {
            return <Navigate to={PATH_AUTH.login} />;
      }

      if (authData.user_type === 'admin' && ADMIN_PATHS_ALLOWED.includes(pathname)) {
            return <>{children}</>
      }

      if (authData.user_type === 'contractors' && CONTRACTOR_PATHS_ALLOWED.includes(pathname)) {
            return <>{children}</>
      }

      if (authData.user_type === 'rental_owners' && RENTAL_OWNER_PATHS_ALLOWED.includes(pathname)) {
            return <>{children}</>
      }
}