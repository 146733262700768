import thunk from "redux-thunk";
import reducers from "./reducers";
import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import { createLogger } from "redux-logger";

const logger = createLogger();

let middleware = [];

if (process.env.REACT_APP_ENV === 'development') {
    middleware = [...middleware, thunk, logger];
} else {
    middleware = [...middleware, thunk];
}

export const store = createStore(
    reducers,
    compose(applyMiddleware(...middleware))
);