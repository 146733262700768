import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";


FormDatePicker.propTypes = {
   name: PropTypes.string
};

export default function FormDatePicker({ name, ...other }) {
   const { control } = useFormContext();

   return (
      <Controller
         name={name}
         control={control}
         render={({ field, fieldState: { error } }) => (
            <DatePicker
               {...field}
               fullWidth
               error={!!error}
               helperText={error?.message}
               {...other}
            />
         )}
      />
   );
}
