import { Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"

export default function ContractorTimeKeepingTable() {
      return (
            <TableContainer sx={{ minWidth: '50vh', maxHeight: '50vh' }}>
                  <Table stickyHeader>
                        <TableHead>
                              <TableRow>
                                    {
                                          tableHeader.map((row) => (
                                                <TableCell align="left" key={row.id}>
                                                      <Typography variant="subtitle2" color={'#51cbce'} fontWeight={700}>{row.label}</Typography>
                                                </TableCell>
                                          ))
                                    }
                              </TableRow>
                        </TableHead>
                        <TableBody>
                              {
                                    timeKeepingRow.map(row => (
                                          <TableRow
                                                key={row.id}
                                                hover
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                          >

                                                <TableCell align="left">{row.equipment_id}</TableCell>
                                                <TableCell align="left">{row.type}</TableCell>
                                                <TableCell align="left">{row.model}</TableCell>
                                                <TableCell align="left">₱ {row.rate}</TableCell>
                                                <TableCell align="left">{row.hours}</TableCell>
                                                <TableCell align="left">
                                                      {
                                                            (row.status === 'in_use')
                                                            ? <Chip size="small" label="In Use" variant="outlined" sx={{ backgroundColor: '#6bd098', color: 'white', fontWeight: 700}}/>
                                                            : <Chip size="small" label="Idle" variant="outlined" sx={{ backgroundColor: '#fbc658', fontWeight: 700 }}/>
                                                      }
                                                </TableCell>
                                          </TableRow>
                                    ))
                              }
                        </TableBody>
                  </Table>
            </TableContainer>
      )
}

const timeKeepingRow = [
      {
            id: 1,
            equipment_id: 'DTC-0001',
            type: 'Backhoe',
            model: 'Komatsu PC30-3',
            rate: '150,000 / Mo',
            hours: '23',
            status: 'in_use'
      },
      {
            id: 2,
            equipment_id: 'DTC-0003',
            type: 'Backhoe',
            model: 'Komatsu PC60',
            rate: '150,000 / Mo',
            hours: '23',
            status: 'idle'
      },
      {
            id: 3,
            equipment_id: 'DVE-0056',
            type: 'Backhoe',
            model: 'Hyundai Robex 200',
            rate: '3,500 / Hr',
            hours: '23',
            status: 'in_use'
      },
      {
            id: 4,
            equipment_id: 'DVE-0048',
            type: 'Backhoe',
            model: 'Hitachi EX120',
            rate: '2,500 / Hr',
            hours: '23',
            status: 'idle'
      },
      {
            id: 5,
            equipment_id: 'FZI-0021',
            type: 'Backhoe',
            model: 'Komatsu PC120',
            rate: '2,500 / Hr',
            hours: '23',
            status: 'idle'
      }
];

const tableHeader = [
      { id: 1, label: 'EQUIPMENT ID' },
      { id: 2, label: 'TYPE' },
      { id: 3, label: 'MAKE - MODEL' },
      { id: 4, label: 'RATE' },
      { id: 5, label: 'HOURS' },
      { id: 6, label: 'STATUS' }
]