import { Box, Button, InputAdornment, Paper, Stack } from "@mui/material";
import AutoComplete from "../../../../../components/Autocomplete";
import { useEffect, useState } from "react";
import useGlobal from "../../../../../hooks/useGlobal";
import useProject from "../../../../../hooks/useProject";
import moment from "moment/moment";
import { LoadingButton } from "@mui/lab";
import AddIcon from '@mui/icons-material/Add';
import { useDispatch } from "react-redux";
import { projectActions } from "../../../../../redux";
import AddContractorModal from "../modals/addContractorModal";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import {
      FormProvider,
      FormTextField,
      FormDatePicker
} from '../../../../../components/hook-form'
import { DatePicker } from "@mui/x-date-pickers";
import Swal from "sweetalert2";
import NumericCustomFormat from "../../../../../components/NumericFormat";

export default function AddProject() {

      const dispatch = useDispatch();

      const {
            fetchContractors
      } = useGlobal();

      const {
            saveProject,
            isAddProject,
            isAddContractorModalOpen
      } = useProject();

      const AddProjectSchema = Yup.object().shape({
            name: Yup.string().required('Project name is required'),
            budget: Yup.number().nullable(),
            location: Yup.string().required('Location is required'),
            billing_start_date: Yup.date().required('Billing start date is required'),
            billing_scheme: Yup.string().required('Billing scheme is required')
      });

      const defaultValues = {
            name: '',
            budget: '',
            contract_duration: '',
            location: '',
            billing_start_date: '',
            billing_scheme: ''
      }

      const methods = useForm({
            resolver: yupResolver(AddProjectSchema),
            defaultValues
      })

      const {
            setValue,
            handleSubmit,
            formState: { error, isSubmitting }
      } = methods;

      const [contractorList, setContractorList] = useState([])

      const handleSelectContractor = (e, v) => {
            setValue('contractor_id', v?.id ?? null)
      }

      const handleStartDateChange = (e) => {
            setValue('start_date', moment(e.$d).format("yyyy-MM-DD"))
      }

      const onSubmit = async (data) => {
            const payload = {
                  ...data,
                  billing_start_date: moment(data.billing_start_date).format("yyyy-MM-DD")
            }

            Swal.fire({
                  title: "Are you sure you want to save this project",
                  text: "",
                  reverseButtons: true,
                  showCancelButton: true,
                  confirmButtonColor: "#51cbce",
                  cancelButtonColor: "#919EAB",
                  confirmButtonText: "Save",
            }).then(async (result) => {
                  if (!result.isConfirmed) {
                        return;
                  } else {
                        await saveProject(payload);
                  }
            });
      }

      const loadContractors = async () => {
            await fetchContractors().then(response => {
                  setContractorList(response);
            }).catch(() => {
                  console.log('Something went wrong')
            });
      }

      useEffect(() => {
            if (isAddProject) {
                  loadContractors();
            }
      }, [isAddProject]);

      return (
            <Box
                  marginTop={5}
                  paddingX={5}
                  paddingY={2}
            >
                  <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Stack
                              direction={'column'}
                              spacing={3}
                        >
                              <FormTextField
                                    name={'name'}
                                    label="Project Name"
                                    placeholder="Enter project name"
                                    variant="outlined"
                              />

                              <Stack
                                    direction={'row'}
                                    spacing={1}
                              >
                                    <AutoComplete
                                          label={'Contractor'}
                                          fullWidth
                                          placeholder="Contractor"
                                          listItem={contractorList}
                                          onChange={(e, v) => handleSelectContractor(e, v)}
                                          PaperComponent={({ children }) => {
                                                return (
                                                      <Paper>
                                                            {children}
                                                            <Button
                                                                  fullWidth
                                                                  variant="contained"
                                                                  sx={{
                                                                        justifyContent: "center",
                                                                        background: '#51cbce',
                                                                        '&:hover': {
                                                                              background: '#51cbce'
                                                                        }
                                                                  }}
                                                                  onMouseDown={() => dispatch(projectActions.openAddContractorModal())}
                                                            >
                                                                  <AddIcon /> Add Contractor
                                                            </Button>
                                                      </Paper>
                                                );
                                          }}
                                    />
                              </Stack>

                              <FormTextField
                                    name={'budget'}
                                    label="Budget"
                                    placeholder="Budget"
                                    InputProps={{
                                          inputComponent: NumericCustomFormat
                                    }}
                              />

                              <Stack
                                    direction={'row'}
                                    spacing={2}
                              >
                                    <DatePicker
                                          label={`Start Date`}
                                          disablePast
                                          onChange={(e) => handleStartDateChange(e)}
                                          slotProps={{
                                                textField: {
                                                      size: "medium",
                                                      readOnly: true,
                                                      fullWidth: true
                                                }
                                          }}
                                    />

                                    <FormTextField
                                          label="Contract Duration"
                                          placeholder="Contract Duration"
                                          fullWidth
                                          variant="outlined"
                                          type="number"
                                          name="contract_duration"
                                    />
                              </Stack>

                              <FormTextField
                                    name="location"
                                    label="Location"
                                    variant="outlined"
                              />

                              <FormDatePicker
                                    name="billing_start_date"
                                    label={`Billing Start Date`}
                                    disablePast
                                    slotProps={{
                                          textField: {
                                                size: "medium",
                                                readOnly: true,
                                                fullWidth: true
                                          }
                                    }}
                              />

                              <FormTextField
                                    name="billing_scheme"
                                    label="Billing Scheme"
                                    variant="outlined"
                              />

                              <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    disableRipple
                                    fullWidth
                                    loading={isSubmitting}
                                    disableTouchRipple
                                    sx={{
                                          borderRadius: '10px',
                                          background: '#51cbce',
                                          '&:hover': {
                                                background: '#51cbce'
                                          }
                                    }}
                              >
                                    Save
                              </LoadingButton>
                        </Stack>

                        {
                              isAddContractorModalOpen && <AddContractorModal />
                        }
                  </FormProvider>
            </Box >
      )
}