import { Box, Card, Divider, Grid, Paper, Stack, Typography } from "@mui/material"
import { RiBillLine } from 'react-icons/ri'
import {
      TbBulldozer,
      TbBackhoe
} from 'react-icons/tb';
import { LuConstruction } from 'react-icons/lu';


export default function RentalOwnerOverviewCards() {

      return (
            <Box
                  display={'flex'}
                  flexDirection={'column'}
                  marginY={3}
            >
                  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                        <Grid item xs={2} sm={2} md={3} key={1}>
                              <Card
                                    component={Paper}
                                    sx={{
                                          py: 1,
                                          px: 2,
                                          borderRadius: '12px'
                                    }}
                              >
                                    <Stack
                                          direction={'row'}
                                          justifyContent={'space-between'}
                                          alignItems={'center'}
                                    >
                                          <Stack
                                                sx={{ px: 4 }}
                                          >
                                                <TbBulldozer color="#6bd098" size={40} />
                                          </Stack>
                                          <Stack
                                                direction={'column'}
                                                alignItems={'flex-end'}
                                                spacing={1}
                                          >
                                                <Typography variant="subtitle1" sx={{ fontWeight: 400, color: '#9A9A9A' }}>Idle Equipments</Typography>
                                                <Typography variant="h5">2</Typography>
                                          </Stack>
                                    </Stack>

                                    <Divider sx={{ my: 2 }} />

                                    <Typography variant="subtitle2" sx={{ fontWeight: 400, color: '#9A9A9A' }}>Now</Typography>
                              </Card>
                        </Grid>

                        <Grid item xs={2} sm={2} md={3} key={2}>
                              <Card
                                    component={Paper}
                                    sx={{
                                          py: 1,
                                          px: 2,
                                          borderRadius: '12px'
                                    }}
                              >
                                    <Stack
                                          direction={'row'}
                                          justifyContent={'space-between'}
                                          alignItems={'center'}
                                    >
                                          <Stack
                                                sx={{ px: 4 }}
                                          >
                                                <LuConstruction color="#fbc658 " size={40} />
                                          </Stack>
                                          <Stack
                                                direction={'column'}
                                                alignItems={'flex-end'}
                                                spacing={1}
                                          >
                                                <Typography variant="subtitle1" sx={{ fontWeight: 400, color: '#9A9A9A' }}>Total Hours</Typography>
                                                <Typography variant="h5">5</Typography>
                                          </Stack>
                                    </Stack>

                                    <Divider sx={{ my: 2 }} />

                                    <Typography variant="subtitle2" sx={{ fontWeight: 400, color: '#9A9A9A' }}>Now</Typography>
                              </Card>
                        </Grid>

                        <Grid item xs={2} sm={2} md={3} key={3}>
                              <Card
                                    component={Paper}
                                    sx={{
                                          py: 1,
                                          px: 2,
                                          borderRadius: '12px'
                                    }}
                              >
                                    <Stack
                                          direction={'row'}
                                          justifyContent={'space-between'}
                                          alignItems={'center'}
                                    >
                                          <Stack
                                                sx={{ px: 4 }}
                                          >
                                                <RiBillLine color="#ef8157 " size={40} />
                                          </Stack>
                                          <Stack
                                                direction={'column'}
                                                alignItems={'flex-end'}
                                                spacing={1}
                                          >
                                                <Typography variant="subtitle1" sx={{ fontWeight: 400, color: '#9A9A9A' }}>Unbilled Hours</Typography>
                                                <Typography variant="h5">₱ 27,623.00</Typography>
                                          </Stack>
                                    </Stack>

                                    <Divider sx={{ my: 2 }} />

                                    <Typography variant="subtitle2" sx={{ fontWeight: 400, color: '#9A9A9A' }}>Now</Typography>
                              </Card>
                        </Grid>

                        <Grid item xs={2} sm={2} md={3} key={4}>
                              <Card
                                    component={Paper}
                                    sx={{
                                          py: 1,
                                          px: 2,
                                          borderRadius: '12px'
                                    }}
                              >
                                    <Stack
                                          direction={'row'}
                                          justifyContent={'space-between'}
                                          alignItems={'center'}
                                    >
                                          <Stack
                                                sx={{ px: 4 }}
                                          >
                                                <TbBackhoe color="#fbc658" size={40} />
                                          </Stack>
                                          <Stack
                                                direction={'column'}
                                                alignItems={'flex-end'}
                                                spacing={1}
                                          >
                                                <Typography variant="subtitle1" sx={{ fontWeight: 400, color: '#9A9A9A' }}>Total Equipments</Typography>
                                                <Typography variant="h5">3</Typography>
                                          </Stack>
                                    </Stack>

                                    <Divider sx={{ my: 2 }} />

                                    <Typography variant="subtitle2" sx={{ fontWeight: 400, color: '#9A9A9A' }}>Now</Typography>
                              </Card>
                        </Grid>
                  </Grid>
            </Box>
      )
}