import { Box, Button, Divider, Grid, Paper, Stack, TextField, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import AddIcon from '@mui/icons-material/Add';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";

import AutoComplete from "../../../../../components/Autocomplete"
import useEquipment from "../../../../../hooks/useEquipment";
import { equipmentType, sizeCategory, trackType } from "../../../../../statics"

import {
      FormProvider,
      FormTextField,
      FormSelect
} from '../../../../../components/hook-form'
import NumericFormatCustom from "../../../../../components/NumericFormat";
import { storeEquipment } from '../../../../../api/rental-owners';
import { getAuthUser } from "../../../../../utils/auth";
import { useState } from "react";
import { equipmentActions } from "../../../../../redux";

export default function AddEquipmentDrawer() {

      const dispatch = useDispatch();

      const user = getAuthUser();

      const {
            isAddEquipmentOwnerModalOpen,
            openAddEquipmentDrawer
      } = useEquipment();


      const EquipmentSchema = Yup.object().shape({
            make: Yup.string().required('Equipment make is required'),
            model: Yup.string().required('Equipment model is required'),
            equipment_type_id: Yup.string().required('Equipment type is required'),
            track_type: Yup.string().required('Equipment track type is required'),
            size_category: Yup.string().required('Size category is required'),
            current_lat: Yup.string().required('This field is required'),
            current_lng: Yup.string().required('This field is required')
      });

      const defaultValues = {
            make: '',
            model: '',
            equipment_type_id: '',
            suggested_similarity: '',
            track_type: '',
            size_category: '',
            subcon_rate_interval: '',
            subcon_rate: '',
            current_lat: '',
            current_lng: '',
            company_prefix: user?.company_details?.company_prefix
      }

      const methods = useForm({
            resolver: yupResolver(EquipmentSchema),
            defaultValues
      })

      const {
            setValue,
            getValues,
            handleSubmit,
            formState: { error, isSubmitting }
      } = methods;


      const handleSubmitEquipmentPayload = async (data) => {
            const payload = { ...data }

            Swal.fire({
                  title: "Are you sure you want to save this new equipment",
                  text: "",
                  reverseButtons: true,
                  showCancelButton: true,
                  confirmButtonColor: "#51cbce",
                  cancelButtonColor: "#919EAB",
                  confirmButtonText: "Save",
            }).then(async (result) => {
                  if (!result.isConfirmed) {
                        return;
                  } else {
                        await storeEquipment(payload).then(() => {
                              openAddEquipmentDrawer()
                              dispatch(equipmentActions.reloadRentalOwnerEquipmentsTable())
                              Swal.fire({
                                    icon: 'success',
                                    title: 'Equipment successfully enrolled'
                              });
                        }).catch(() => {
                              Swal.fire({
                                    icon: 'error',
                                    title: 'Unable to enroll equipment'
                              });
                        });
                  }
            });

            
      }

      return (
            <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitEquipmentPayload)}>
                  <Box
                        marginTop={2}
                        paddingX={5}
                        paddingY={2}
                        sx={{ flexGrow: 1 }}
                  >
                        <Typography variant="h6" sx={{ mb: 3 }}>Equipment Model</Typography>

                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 8 }}>
                              <Grid item xs={2} sm={4} md={4} key={1}>
                                    <FormTextField
                                          name="make"
                                          label="Make"
                                          fullWidth
                                          placeholder="Make"
                                          variant="outlined"
                                    />
                              </Grid>
                              <Grid item xs={2} sm={4} md={4} key={2}>
                                    <FormTextField
                                          name="model"
                                          fullWidth
                                          label="Model"
                                          placeholder="Model"
                                          variant="outlined"
                                    />

                              </Grid>
                              <Grid item xs={2} sm={4} md={4} key={3}>
                                    <FormSelect
                                          name={'equipment_type_id'}
                                          label={'Equipment Type'}
                                          placeholder="Equipment Type"
                                          fullWidth
                                    >
                                          <option value={""} />
                                          {
                                                equipmentType.filter(row => row.value !== 'any').map(row => (
                                                      <option
                                                            key={row.id}
                                                            value={row.value}
                                                      >
                                                            {row.label}
                                                      </option>
                                                ))
                                          }
                                    </FormSelect>
                              </Grid>
                              <Grid item xs={2} sm={4} md={4} key={4}>
                                    <FormTextField
                                          name="suggested_similarity"
                                          label="Suggested Similarity"
                                          placeholder="Suggested Similarity"
                                          fullWidth
                                          variant="outlined"
                                    />
                              </Grid>
                              <Grid item xs={2} sm={4} md={4} key={5}>
                                    <FormSelect
                                          name={'track_type'}
                                          label={'Track Type'}
                                          placeholder="Track Type"
                                          fullWidth
                                    >
                                          <option value={""} />
                                          {
                                                trackType.map(row => (
                                                      <option
                                                            key={row.id}
                                                            value={row.value}
                                                      >
                                                            {row.label}
                                                      </option>
                                                ))
                                          }
                                    </FormSelect>
                              </Grid>
                              <Grid item xs={2} sm={4} md={4} key={6}>
                                    <FormSelect
                                          name={'size_category'}
                                          label={'Size Category'}
                                          placeholder="Size Category"
                                          fullWidth
                                    >
                                          <option value={""} />
                                          {
                                                sizeCategory.map(row => (
                                                      <option
                                                            key={row.id}
                                                            value={row.value}
                                                      >
                                                            {row.label}
                                                      </option>
                                                ))
                                          }
                                    </FormSelect>
                              </Grid>
                        </Grid>

                        <Divider sx={{ my: 5 }} />

                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 8 }}>

                              {/* <Grid item xs={2} sm={4} md={4} key={7}>
                                    <AutoComplete
                                          name={'current_project_id'}
                                          label={'Project'}
                                          placeholder="Select Project"
                                          fullWidth
                                          PaperComponent={({ children }) => {
                                                return (
                                                      <Paper>
                                                            {children}
                                                            <Button
                                                                  fullWidth
                                                                  variant="contained"
                                                                  sx={{
                                                                        justifyContent: "center",
                                                                        background: '#51cbce',
                                                                        '&:hover': {
                                                                              background: '#51cbce'
                                                                        }
                                                                  }}
                                                                  onMouseDown={() => {
                                                                        console.log("Add new project");
                                                                  }}
                                                            >
                                                                  <AddIcon /> Add Project
                                                            </Button>
                                                      </Paper>
                                                );
                                          }}
                                    />
                              </Grid> */}

                              {/* <Grid item xs={2} sm={4} md={4} key={9}>
                                    <AutoComplete
                                          name={'dedicated_operator_id'}
                                          label={'Operator'}
                                          placeholder="Select Operator"
                                          fullWidth
                                          PaperComponent={({ children }) => {
                                                return (
                                                      <Paper>
                                                            {children}
                                                            <Button
                                                                  fullWidth
                                                                  variant="contained"
                                                                  sx={{
                                                                        justifyContent: "center",
                                                                        background: '#51cbce',
                                                                        '&:hover': {
                                                                              background: '#51cbce'
                                                                        }
                                                                  }}
                                                                  onMouseDown={() => {
                                                                        console.log("Add new");
                                                                  }}
                                                            >
                                                                  <AddIcon /> Add Operator
                                                            </Button>
                                                      </Paper>
                                                );
                                          }}
                                    />
                              </Grid> */}

                              {/* <Grid item xs={2} sm={4} md={4} key={10}>
                                    <FormSelect
                                          name={'equipment_status'}
                                          label={'Status'}
                                          placeholder="Status"
                                          fullWidth
                                    >
                                          <option value={""}/>
                                          {
                                                equipmentStatus.map(row => (
                                                      <option
                                                            key={row.id}
                                                            value={row.value}
                                                      >
                                                            {row.label}
                                                      </option>
                                                ))
                                          }
                                    </FormSelect>
                              </Grid> */}

                              <Grid item xs={2} sm={4} md={4} key={7}>
                                    <FormTextField
                                          name="current_lat"
                                          label="Current Latitude"
                                          placeholder="Current Latitude"
                                          fullWidth
                                          variant="outlined"
                                    />
                              </Grid>

                              <Grid item xs={2} sm={4} md={4} key={8}>
                                    <FormTextField
                                          name="current_lng"
                                          label="Current Longitude"
                                          placeholder="Current Longitude"
                                          fullWidth
                                          variant="outlined"
                                    />
                              </Grid>

                              <Grid item xs={2} sm={4} md={4} key={9}>
                                    <FormSelect
                                          name={'subcon_rate_interval'}
                                          label={'Subcon Rate Interval'}
                                          placeholder="Subcon Rate Interval"
                                          fullWidth
                                    >
                                          <option value={""} />
                                          <option
                                                key={'mo'}
                                                value={'monthly'}
                                          >
                                                Monthly
                                          </option>
                                          <option
                                                key={'hr'}
                                                value={'hourly'}
                                          >
                                                Hourly
                                          </option>
                                    </FormSelect>
                              </Grid>

                              <Grid item xs={2} sm={4} md={4} key={10}>
                                    <FormTextField
                                          name="subcon_rate"
                                          label="Subcon Rate"
                                          placeholder="Subcon Rate"
                                          fullWidth
                                          variant="outlined"
                                          InputProps={{
                                                inputComponent: NumericFormatCustom
                                          }}
                                    />
                              </Grid>

                              {/* <Grid item xs={2} sm={4} md={4} key={15}>
                                    <FormTextField
                                          name="demitech_commision"
                                          label="Demitech Commision"
                                          placeholder="Demitech Commision"
                                          fullWidth
                                          variant="outlined"
                                    />
                              </Grid> */}

                              <Grid item xs={2} sm={4} md={4} key={16}></Grid>
                        </Grid>

                        <LoadingButton
                              variant="contained"
                              disableRipple
                              fullWidth
                              type="submit"
                              loading={isSubmitting}
                              disableTouchRipple
                              sx={{
                                    borderRadius: '10px',
                                    background: '#51cbce',
                                    mt: 3,
                                    '&:hover': {
                                          background: '#51cbce'
                                    }
                              }}
                        >
                              Save
                        </LoadingButton>
                  </Box>

            </FormProvider>
      )
}