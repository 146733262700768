import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  Button,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { RiBankCardLine } from "react-icons/ri";
import { TbBackhoe } from "react-icons/tb";
import { SlGraph } from "react-icons/sl";
import { RiBillLine } from "react-icons/ri";
import Logo from "../../../assets/logo192.png";
import { useNavigate, useLocation } from "react-router-dom";
import { PATH_RENTAL_OWNER_DASHBOARD } from "../../../routes/paths";
import { BiLogOut } from "react-icons/bi";
import useAuth from "../../../hooks/useAuth";
import { useState, useEffect } from "react";
import useGlobal from "../../../hooks/useGlobal";
import useResponsive from "../../../hooks/useResponsive";
import { useTheme } from "@mui/material/styles";
export default function NavigationBar() {
  const isDesktop = useResponsive("up", "lg");

  const { reloadPage, toggleNavbar } = useGlobal();

  const { logout } = useAuth();

  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState();
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    // Define the pathnames for each menu item
    const paths = [
      PATH_RENTAL_OWNER_DASHBOARD.dashboard.overview.root,
      PATH_RENTAL_OWNER_DASHBOARD.dashboard.equipments.root,
      PATH_RENTAL_OWNER_DASHBOARD.dashboard.payouts.root,
    ];

    // Find the index of the current location in the paths array
    const selectedIndex = paths.findIndex((path) =>
      location.pathname.includes(path)
    );

    if (selectedIndex !== -1) {
      setSelectedTab(selectedIndex); // Update the selectedTab state based on the index
    }
  }, [location]);
  const handleNavigate = (page, index) => {
    switch (page) {
      case "overview":
        setSelectedTab(index);
        navigate(PATH_RENTAL_OWNER_DASHBOARD.dashboard.overview.root);
        reloadPage();
        if (!isDesktop) {
          toggleNavbar();
        }
        break;
      case "equipments":
        setSelectedTab(index);
        navigate(PATH_RENTAL_OWNER_DASHBOARD.dashboard.equipments.root);
        reloadPage();
        if (!isDesktop) {
          toggleNavbar();
        }
        break;
      case "payouts":
        setSelectedTab(index);
        navigate(PATH_RENTAL_OWNER_DASHBOARD.dashboard.payouts.root);
        reloadPage();
        if (!isDesktop) {
          toggleNavbar();
        }
        break;
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 300,
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        spacing={4}
        sx={{
          py: 3,
          px: 3,
        }}
      >
        <Avatar alt="React Icon" src={Logo} sx={{ width: 24, height: 24 }} />
        <Typography sx={{ textTransform: "uppercase", color: "white" }}>
          DEMITECH
        </Typography>
      </Stack>

      <Stack sx={{ px: 2 }}>
        <Divider color="white" sx={{ display: "flex" }} />
      </Stack>

      <List sx={{ px: 1 }}>
        <ListItem disablePadding sx={{ py: 2, marginLeft: 2 }}>
          <Button
            disableRipple
            sx={{
              opacity: selectedTab === 0 ? 1 : 0.7,
              "&:hover": {
                opacity: 1,
                backgroundColor: "transparent",
              },
            }}
            startIcon={
              <SlGraph
                size={22}
                style={{
                  color:
                    selectedTab === 0 ? theme.palette.info.main_2 : "white",
                }}
              />
            }
            selected={selectedTab === 0}
            disabled={selectedTab === 0}
            onClick={() => handleNavigate("overview", 0)}
          >
            <Typography
              sx={{
                fontSize: 13,
                marginLeft: "12px",
                letterSpacing: 0,
                color: selectedTab === 0 ? theme.palette.info.main_2 : "white",
                textTransform: "uppercase",
              }}
            >
              Overview
            </Typography>
          </Button>
        </ListItem>

        <ListItem disablePadding sx={{ py: 2, marginLeft: 2, marginLeft: 2 }}>
          <Button
            disableRipple
            sx={{
              opacity: selectedTab === 1 ? 1 : 0.7,
              "&:hover": {
                opacity: 1,
                backgroundColor: "transparent",
              },
            }}
            startIcon={
              <TbBackhoe
                size={22}
                style={{
                  color:
                    selectedTab === 1 ? theme.palette.info.main_2 : "white",
                }}
              />
            }
            selected={selectedTab === 1}
            disabled={selectedTab === 1}
            onClick={() => handleNavigate("equipments", 1)}
          >
            <Typography
              sx={{
                fontSize: 13,
                marginLeft: "12px",
                letterSpacing: 0,
                color: selectedTab === 1 ? theme.palette.info.main_2 : "white",
                textTransform: "uppercase",
              }}
            >
              Equiments
            </Typography>
          </Button>
        </ListItem>

        <ListItem disablePadding sx={{ py: 2, marginLeft: 2 }}>
          <Button
            disableRipple
            sx={{
              opacity: selectedTab === 2 ? 1 : 0.7,
              "&:hover": {
                opacity: 1,
                backgroundColor: "transparent",
              },
            }}
            startIcon={
              <RiBillLine
                size={22}
                style={{
                  color:
                    selectedTab === 2 ? theme.palette.info.main_2 : "white",
                }}
              />
            }
            selected={selectedTab === 2}
            disabled={selectedTab === 2}
            onClick={() => handleNavigate("payouts", 2)}
          >
            <Typography
              sx={{
                fontSize: 13,
                marginLeft: "12px",
                letterSpacing: 0,
                color: selectedTab === 2 ? theme.palette.info.main_2 : "white",

                textTransform: "uppercase",
              }}
            >
              Payouts
            </Typography>
          </Button>
        </ListItem>

        <ListItem disablePadding sx={{ py: 2, marginLeft: 2 }}>
          <Button
            disableRipple
            startIcon={<BiLogOut size={22} color="white" />}
            sx={{
              opacity: 0.7,
              "&.Mui-selected": {
                backgroundColor: "info.main_2",
                opacity: 1,
              },
              "&.Mui-disabled": {
                backgroundColor: "info.main_2",
                opacity: 1,
              },

              "&:hover": {
                opacity: 1,
                backgroundColor: "transparent",
              },
            }}
            onClick={() => logout()}
          >
            <Typography
              sx={{
                fontSize: 13,
                marginLeft: "12px",
                letterSpacing: 0,
                color: "white",
                textTransform: "uppercase",
              }}
            >
              Logout
            </Typography>
          </Button>
        </ListItem>
      </List>
    </Box>
  );
}
