function path(root, sublink) {
      return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_CONTRACTOR_DASHBOARD = '/contractor';
const ROOTS_RENTAL_OWNER_DASHBOARD = '/rental-owner';
const ROOTS_ADMIN_DASHBOARD = '/admin';



export const PATH_AUTH = {
      root: ROOTS_AUTH,
      otherRoot: path(ROOTS_AUTH, '/'),
      login: path(ROOTS_AUTH, '/login'),
      register: path(ROOTS_AUTH, '/register')
}

export const PATH_ADMIN_DASHBOARD = {
      root: ROOTS_ADMIN_DASHBOARD,
      dashboard: {
            equipments: {
                  root: path(ROOTS_ADMIN_DASHBOARD, '/equipments')
            },
            projects: {
                  root: path(ROOTS_ADMIN_DASHBOARD, '/projects')
            },
            equipment_request: {
                  root: path(ROOTS_ADMIN_DASHBOARD, '/equipment-request')
            },
            de_mobilization: {
                  root: path(ROOTS_ADMIN_DASHBOARD, '/de-mobilization')
            },
            payouts: {
                  root: path(ROOTS_ADMIN_DASHBOARD, '/payouts')
            }
      }
}

export const PATH_CONTRACTOR_DASHBOARD = {
      root: ROOTS_CONTRACTOR_DASHBOARD,
      dashboard: {
            timekeeping: {
                  root: path(ROOTS_CONTRACTOR_DASHBOARD, '/timekeeping')
            },
            projects: {
                  root: path(ROOTS_CONTRACTOR_DASHBOARD, '/projects')
            },
            billing: {
                  root: path(ROOTS_CONTRACTOR_DASHBOARD, '/billing')
            }
      }
}

export const PATH_RENTAL_OWNER_DASHBOARD = {
      root: ROOTS_RENTAL_OWNER_DASHBOARD,
      dashboard: {
            overview: {
                  root: path(ROOTS_RENTAL_OWNER_DASHBOARD, '/overview')
            },
            equipments: {
                  root: path(ROOTS_RENTAL_OWNER_DASHBOARD, '/equipments')
            },
            payouts: {
                  root: path(ROOTS_RENTAL_OWNER_DASHBOARD, '/payouts')
            }
      }
}