import { FormProvider, FormRadioGroup, FormSelect, FormTextField } from "../../../components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Page from "../../../components/Page";
import { Alert, AlertTitle, Avatar, Box, Card, Container, Divider, Grid, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import Logo from '../../../assets/logo192.png'
import { object, string, ref } from "yup";
import { useState } from "react";
import Iconify from "../../../components/Iconify";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { PATH_AUTH } from "../../../routes/paths";
import { register } from "../../../api/authentication";
import Swal from "sweetalert2";

export default function Register() {

      const navigate = useNavigate();

      const [showPassword, setShowPassword] = useState(false);

      const [showPasswordFor, setShowPasswordFor] = useState("")

      const getCharacterValidationError = (str: string) => {
            return `Your password must have at least 1 ${str} character`;
      };

      const RegistrationSchema = Yup.object().shape({
            name: Yup.string().required('Name is required'),
            username: Yup.string().required('Username is required'),
            user_type: Yup.string().required('Account type is required'),
            company_name: Yup.string().min(6, 'Company name must have at least 6 characters').required('Company name is required'),
            email: Yup.string()
                  .email("Email must be a valid email address")
                  .required('Email is required'),
            password: Yup.string()
                  .min(8, "Password must have at least 8 characters")
                  .matches(/[0-9]/, getCharacterValidationError("digit"))
                  .matches(/[a-z]/, getCharacterValidationError("lowercase"))
                  .matches(/[A-Z]/, getCharacterValidationError("uppercase")),
            confirmPassword: string()
                  .required("Please re-type your password")
                  .oneOf([ref("password")], "Passwords does not match"),
      });

      const defaultValues = {
            name: '',
            username: '',
            user_type: '',
            company_name: '',
            company_address: '',
            email: '',
            password: '',
            confirmPassword: ''
      }

      const methods = useForm({
            resolver: yupResolver(RegistrationSchema),
            defaultValues
      })

      const {
            setError,
            handleSubmit,
            clearErrors,
            formState: { errors, isSubmitting }
      } = methods;


      const handleShowPassword = (showPassFor) => {
            setShowPassword(!showPassword);
            setShowPasswordFor(showPassFor)
      }

      const onSubmit = async (data) => {
            await register(data).then(response => {
                  Swal.fire({
                        icon: 'success',
                        title: 'Successfully registered.'
                  }).then(result => {
                        if (!result.isConfirmed) {
                              return;
                        } else {
                              navigate(PATH_AUTH.login)
                        }
                  });
            }).catch(error => {
                  setError('afterSubmit', { ...error, message: error.message })
                  // clearErrors()
            })
      }

      return (
            <Page title="Register">
                  <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Container
                              maxWidth="sm"
                        >
                              <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{ minHeight: '100vh' }}
                              >
                                    <Grid item xs={3}>
                                          <Box
                                                component={Card}
                                                width={400}
                                                height={'100%'}
                                                paddingX={5}
                                                paddingY={5}
                                                sx={{ borderRadius: '12px' }}
                                          >
                                                <Stack
                                                      direction={'row'}
                                                      justifyContent={'center'}
                                                      alignItems={'center'}
                                                      spacing={2}
                                                >
                                                      <Avatar src={Logo} />
                                                      <Typography variant="h5">DEMITECH</Typography>
                                                </Stack>

                                                <Divider sx={{ my: 2, mx: 2 }} />

                                                <Stack
                                                      direction={'column'}
                                                      spacing={2}
                                                      sx={{
                                                            mt: 2
                                                      }}
                                                >
                                                      {
                                                            (!!errors.afterSubmit) &&
                                                            <Alert severity="error">
                                                                  <AlertTitle>{errors.afterSubmit.message}</AlertTitle>
                                                                  Username or Company name already exists.
                                                            </Alert>
                                                      }

                                                      <FormTextField
                                                            name={'name'}
                                                            label="Name"
                                                            placeholder="Name"
                                                            inputProps={{
                                                                  autoComplete: 'new-password',
                                                                  form: {
                                                                        autocomplete: 'off'
                                                                  }
                                                            }}
                                                      />

                                                      <FormTextField
                                                            name={'email'}
                                                            label="Email"
                                                            placeholder="Email"
                                                            inputProps={{
                                                                  autoComplete: 'new-password',
                                                                  form: {
                                                                        autocomplete: 'off'
                                                                  }
                                                            }}
                                                      />

                                                      <FormTextField
                                                            name={'username'}
                                                            label="Username"
                                                            placeholder="Username"
                                                            inputProps={{
                                                                  autoComplete: 'new-password',
                                                                  form: {
                                                                        autocomplete: 'off'
                                                                  }
                                                            }}
                                                      />

                                                      <FormSelect
                                                            name={'user_type'}
                                                            label={'Choose account type..'}
                                                            inputProps={{
                                                                  autoComplete: 'new-password',
                                                                  form: {
                                                                        autocomplete: 'off'
                                                                  }
                                                            }}
                                                      >
                                                            <option value={""} />
                                                            {
                                                                  userType.map(type => {
                                                                        return (
                                                                              <option
                                                                                    key={type.id}
                                                                                    value={type.value}
                                                                              >
                                                                                    {type.label}
                                                                              </option>
                                                                        )
                                                                  })
                                                            }
                                                      </FormSelect>

                                                      <FormTextField
                                                            name={'company_name'}
                                                            label="Company Name"
                                                            placeholder="Company Name"
                                                            inputProps={{
                                                                  autoComplete: 'new-password',
                                                                  form: {
                                                                        autocomplete: 'off'
                                                                  }
                                                            }}
                                                      />

                                                      <FormTextField
                                                            name={'company_address'}
                                                            label="Company Address"
                                                            placeholder="Company Address"
                                                            inputProps={{
                                                                  autoComplete: 'new-password',
                                                                  form: {
                                                                        autocomplete: 'off'
                                                                  }
                                                            }}
                                                      />

                                                      <FormTextField
                                                            name={'password'}
                                                            label="Password"
                                                            placeholder="Password"
                                                            type={(showPassword && showPasswordFor === 'password') ? 'text' : 'password'}
                                                            InputProps={{
                                                                  endAdornment: (
                                                                        <InputAdornment position="end">
                                                                              <IconButton
                                                                                    onClick={() => handleShowPassword('password')}
                                                                                    edge="end"
                                                                              >
                                                                                    <Iconify
                                                                                          icon={
                                                                                                (showPassword && showPasswordFor === 'password')
                                                                                                      ? "eva:eye-fill"
                                                                                                      : "eva:eye-off-fill"
                                                                                          }
                                                                                    />
                                                                              </IconButton>
                                                                        </InputAdornment>
                                                                  )
                                                            }}
                                                      />

                                                      <FormTextField
                                                            name={'confirmPassword'}
                                                            label="Confirm Password"
                                                            placeholder="Confirm Password"
                                                            type={(showPassword && showPasswordFor === 'confirmPassword') ? 'text' : 'password'}
                                                            InputProps={{
                                                                  endAdornment: (
                                                                        <InputAdornment position="end">
                                                                              <IconButton
                                                                                    onClick={() => handleShowPassword('confirmPassword')}
                                                                                    edge="end"
                                                                              >
                                                                                    <Iconify
                                                                                          icon={
                                                                                                (showPassword && showPasswordFor === 'confirmPassword')
                                                                                                      ? "eva:eye-fill"
                                                                                                      : "eva:eye-off-fill"
                                                                                          }
                                                                                    />
                                                                              </IconButton>
                                                                        </InputAdornment>
                                                                  )
                                                            }}
                                                      />

                                                      <LoadingButton
                                                            variant="contained"
                                                            disableRipple
                                                            disableTouchRipple
                                                            type="submit"
                                                            loading={isSubmitting}
                                                            sx={{
                                                                  backgroundColor: '#51cbce',
                                                                  textTransform: 'none',
                                                                  background: '#51cbce',
                                                                  '&:hover': {
                                                                        background: '#51cbce'
                                                                  }
                                                            }}
                                                      >
                                                            Sign Up
                                                      </LoadingButton>
                                                      <Typography
                                                            variant="subtitle2"
                                                            sx={{
                                                                  textAlign: 'center',
                                                                  paddingTop: '5px',
                                                                  cursor: 'pointer',
                                                                  '&:hover': {
                                                                        color: '#51cbce'
                                                                  }
                                                            }}
                                                            onClick={() => navigate(PATH_AUTH.login)}
                                                      >
                                                            Already registered?
                                                      </Typography>
                                                </Stack>
                                          </Box>
                                    </Grid>
                              </Grid>
                        </Container>
                  </FormProvider>
            </Page>
      )
}

const userType = [
      { id: 'contractors', label: 'Contractor', value: 'contractors' },
      { id: 'rental_owners', label: 'Rental Owner', value: 'rental_owners' },
      // { id: 'operators', label: 'Operator', value: 'operators' },
      // { id: 'brokers', label: 'Broker', value: 'brokers' },
]