import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store'
import { ProjectProvider } from './context/project-context';
import { EquipmentProvider } from './context/equipment-context';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ThemeProvider from './theme';
import 'dayjs/locale/en';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <HelmetProvider>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
            <ProjectProvider>
              <EquipmentProvider>
                <Router>
                  <App />
                </Router>
              </EquipmentProvider>
            </ProjectProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </HelmetProvider>
  </Provider>
);
