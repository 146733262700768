import { createContext } from "react";
import { equipmentActions, equipmentInitialState } from "../redux";
import { useDispatch, useSelector } from "react-redux";

const EquipmentContext = createContext({
      ...equipmentInitialState,
      openAddEquipmentDrawer: () => null,
      openEditEquipmentDrawer: () => null
});

const EquipmentProvider = ({ children }) => {

      const dispatch = useDispatch();

      const state = useSelector((state) => state.equipment);

      const openAddEquipmentDrawer = () => {
            dispatch(equipmentActions.addEquipmentDrawer());
      }

      const openEditEquipmentDrawer = () => {
            dispatch(equipmentActions.editEquipmentDrawer());
      }

      return (
            <EquipmentContext.Provider
                  value={{
                        ...state,
                        openAddEquipmentDrawer,
                        openEditEquipmentDrawer
                  }}
            >
                  {children}
            </EquipmentContext.Provider>
      )
}

export { EquipmentContext, EquipmentProvider }