import { Avatar, Box, Divider, IconButton, Stack } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import useGlobal from "../../../hooks/useGlobal";
import useAuth from '../../../hooks/useAuth';
import { deepPurple } from '@mui/material/colors';

export default function Header() {

      const { authData } = useAuth();

      const { toggleNavbar } = useGlobal();

      return (
            <Box
                  paddingX={2}
                  paddingY={2}
            >
                  <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                  >
                        <IconButton onClick={() => toggleNavbar()}>
                              <MenuIcon />
                        </IconButton>

                        {
                              (authData?.avatar)
                              ? <Avatar src={authData?.avatar}/>
                              : <Avatar sx={{ bgcolor: deepPurple[400] }}>{authData.name.charAt(0).toUpperCase()}{authData.name.charAt(1).toUpperCase()}</Avatar>
                        }
                        
                  </Stack>

                  <Stack
                        sx={{
                              py: 2
                        }}
                  >
                        <Divider />
                  </Stack>
            </Box>
      )
}