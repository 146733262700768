import { Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"

export default function RentalOwnerEquipmentsTable({
      equipmentList = []
}) {
      return (
            <TableContainer sx={{ minWidth: '50vh', maxHeight: '50vh' }}>
                  <Table stickyHeader>
                        <TableHead>
                              <TableRow>
                                    {
                                          tableHeader.map((row) => (
                                                <TableCell align="left" key={row.id}>
                                                      <Typography variant="subtitle2" color={'#51cbce'} fontWeight={700}>{row.label}</Typography>
                                                </TableCell>
                                          ))
                                    }
                              </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                                    equipmentList.map(row => (
                                          <TableRow
                                                key={row.id}
                                                hover
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                          >

                                                <TableCell align="left">{row?.uuid}</TableCell>
                                                <TableCell align="left">{row?.equipment_type_id.charAt(0).toUpperCase() + row?.equipment_type_id.slice(1)}</TableCell>
                                                <TableCell align="left">{row.make} {row.model}</TableCell>
                                                <TableCell align="left">₱ {
                                                      (row?.subcon_month_rate)
                                                            ? row.subcon_month_rate.toLocaleString(undefined, { maximumFractionDigits: 2 }) + ' / Mo'
                                                            : row.subcon_hour_rate.toLocaleString(undefined, { maximumFractionDigits: 2 }) + ' / Hr'
                                                }
                                                </TableCell>
                                                <TableCell align="left">{row?.hours ?? 'N/A'}</TableCell>
                                                <TableCell align="left">
                                                      {
                                                            (!row.is_idle)
                                                                  ? <Chip size="small" label="In Use" variant="outlined" sx={{ backgroundColor: '#6bd098', color: 'white', fontWeight: 700 }} />
                                                                  : <Chip size="small" label="Idle" variant="outlined" sx={{ backgroundColor: '#fbc658', fontWeight: 700 }} />
                                                      }
                                                </TableCell>
                                          </TableRow>
                                    ))
                              }
                        </TableBody>
                  </Table>
            </TableContainer>
      )
}

const tableHeader = [
      { id: 1, label: 'EQUIPMENT ID' },
      { id: 2, label: 'TYPE' },
      { id: 3, label: 'MAKE - MODEL' },
      { id: 4, label: 'RATE' },
      { id: 5, label: 'HOURS' },
      { id: 6, label: 'STATUS' }
]