import { Alert, Box, Card, Container, Grid, Stack, Typography } from "@mui/material";
import Page from "../../../../../components/Page";
import { useNavigate } from "react-router-dom";
import { PATH_AUTH } from "../../../../../routes/paths";
import { useEffect } from "react";


export default function GoogleSignInFailed() {

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
        navigate(PATH_AUTH.login)
    }, 5000)
  }, [])

  return (
    <Page title="Sign-in Failed">
      <Container maxWidth="sm">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '100vh' }}
        >
          <Grid item xs={3}>
            <Box
              component={Card}
              width={500}
              height={'100%'}
              paddingX={5}
              paddingY={5}
              sx={{ borderRadius: '12px' }}
            >
              <Stack
                direction={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                spacing={2}
              >
                <Alert severity="error">
                  Google sign-in failed. redirecting to loging page.
                </Alert>

                <Typography
                  variant="subtitle2"
                  sx={{
                    textAlign: 'center',
                    paddingTop: '5px',
                    cursor: 'pointer'
                  }}
                >
                  Click <span onClick={() => navigate(PATH_AUTH.login)} style={{ color: '#51cbce' }}>here</span> if you're not redirected automatically on the login page.
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}