import { Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import useEquipment from "../../../../../hooks/useEquipment"
import { formatAsPercentage } from "../../../../../utils/formatAsPercentage";

export default function EquipmentTable({
      equipmentList = []
}) {

      const {
            openEditEquipmentDrawer
      } = useEquipment();

      return (
            <TableContainer sx={{ minWidth: '50vh', maxHeight: '50vh' }}>
                  <Table stickyHeader>
                        <TableHead>
                              <TableRow>
                                    {
                                          tableHeader.map((row) => (
                                                <TableCell align="left" key={row.id}>
                                                      <Typography variant="subtitle2" color={'#51cbce'} fontWeight={700}>{row.label}</Typography>
                                                </TableCell>
                                          ))
                                    }
                              </TableRow>
                        </TableHead>
                        <TableBody>
                              {
                                    equipmentList.map(row => (
                                          <TableRow
                                                key={row.id}
                                                hover
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                          >

                                                <TableCell align="left">{row?.uuid}</TableCell>
                                                <TableCell align="left">{row?.company_name}</TableCell>
                                                <TableCell align="left">{row?.equipment_type_id.charAt(0).toUpperCase() + row?.equipment_type_id.slice(1)}</TableCell>
                                                <TableCell align="left">{row.make} {row.model}</TableCell>
                                                <TableCell align="left">{<Chip color="info" label={row.equipment_status_label} />}</TableCell>
                                                <TableCell align="left">N/A</TableCell>
                                                <TableCell align="left">N/A</TableCell>
                                                <TableCell align="left">{formatAsPercentage(row.demitech_commission)}</TableCell>
                                          </TableRow>
                                    ))
                              }
                        </TableBody>
                  </Table>
            </TableContainer>
      )
}

const tableHeader = [
      { id: 1, label: 'EQUIPMENT ID' },
      { id: 2, label: 'OWNER' },
      { id: 3, label: 'TYPE' },
      { id: 4, label: 'MAKE - MODEL' },
      { id: 5, label: 'STATUS' },
      { id: 6, label: 'LAST ACTIVE' },
      { id: 7, label: 'EST. AVAILABILITY' },
      { id: 8, label: 'COMMISSION RATE' }
]