import { createContext } from "react";
import { projectActions, projectInitialState } from "../redux";
import { useDispatch, useSelector } from "react-redux";

const ProjectContext = createContext({
      ...projectInitialState,
      openAddProjectDrawer: () => null
});

const ProjectProvider = ({ children }) => {

      const dispatch = useDispatch();

      const state = useSelector((state) => state.project);

      const openAddProjectDrawer = () => {
            dispatch(projectActions.addProject());
      }

      return (
            <ProjectContext.Provider
                  value={{
                        ...state,
                        openAddProjectDrawer
                  }}
            >
                  {children}
            </ProjectContext.Provider>
      )

}

export { ProjectContext, ProjectProvider }