import { authTypes } from "./authentication.types";

export const authInitialState = {
      isAuthenticated: false,
      loading: false,
      error: false,
      logout: false,
      authData: {},
      errorMessage: {}
};

export const authentication = (state = authInitialState, action) => {
      switch (action.type) {
            case authTypes.VERIFY_AUTH_SUCCESS:
                  return {
                        ...state,
                        isAuthenticated: true,
                        error: false,
                        authData: action.payload
                  };
            case authTypes.VERIFY_AUTH_FAILED:
                  return {
                        ...state,
                        isAuthenticated: false,
                        errorMessage: {}
                  };
            case authTypes.LOGIN_SUCCESS:
                  return {
                        ...state,
                        isAuthenticated: true,
                        error: false,
                        authData: action.payload
                  };
            case authTypes.LOGIN_FAILURE:
                  return {
                        ...state,
                        isAuthenticated: false,
                        errorMessage: action.payload
                  };
            case authTypes.LOGOUT_SUCCESS:
                  return {
                        ...state,
                        isAuthenticated: false,
                        errorMessage: {},
                        authData: action.payload
                  };
            case authTypes.VALIDATE_TOKEN_SUCCESS:
                  return {
                        ...state,
                  }
            case authTypes.VALIDATE_TOKEN_FAILED:
                  return {
                        isAuthenticated: false,
                        errorMessage: {}
                  };

            case authTypes.GOOGLE_REGISTRATION_SUCCESS:
                  return {
                        ...state,
                        isAuthenticated: true,
                        error: false,
                        authData: action.payload
                  }
            case authTypes.GOOGLE_SIGNIN_SUCCESS:
                  return {
                        ...state,
                        isAuthenticated: true,
                        error: false,
                        authData: action.payload
                  }

            case authTypes.FACEBOOK_REGISTRATION_SUCCESS:
                  return {
                        ...state,
                        isAuthenticated: true,
                        error: false,
                        authData: action.payload
                  }
            case authTypes.FACEBOOK_SIGNIN_SUCCESS:
                  return {
                        ...state,
                        isAuthenticated: true,
                        error: false,
                        authData: action.payload
                  }

            default:
                  return state;
      };
};