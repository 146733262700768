import { Box, Card } from "@mui/material";

import Page from "../../../components/Page";
import AutoComplete from "../../../components/Autocomplete";
import PayoutsTable from "./components/table";


export default function RentalOwnerPayout() {


  return (
    <Page title="Payout">
      <Box
        component={Card}
        sx={{
          borderRadius: '12px',
          py: 2,
          px: 2
        }}
      >
        <div style={{ marginBottom: '20px', marginLeft: '10px' }}>
          <AutoComplete
            placeholder="Select..."
            size="small"
            listItem={projectStatuses}
            sx={{
              width: '30vh'
            }}
          />

        </div>
        <PayoutsTable />
      </Box>
    </Page>
  )
}

const projectStatuses = [
  { value: 'pending', label: 'Pending Check Writing' },
  { value: 'acknowledged', label: 'For Approval' },
  { value: 'released', label: 'Check Released' },
]