import { projectTypes } from './project.types';

export const projectActions = {
      addProject
};

function addProject() {
      return dispatch => {
            dispatch({
                  type: projectTypes.IS_ADD_PROJECT
            });
      };
};