import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { PATH_ADMIN_DASHBOARD, PATH_CONTRACTOR_DASHBOARD, PATH_RENTAL_OWNER_DASHBOARD } from "../routes/paths";

GuestGuard.propTypes = {
      children: PropTypes.node
}


export default function GuestGuard({ children }) {

      const { isAuthenticated, authData } = useAuth();

      if (isAuthenticated) {
            switch (authData.user_type) {
                  case 'admin':
                        return <Navigate to={PATH_ADMIN_DASHBOARD.dashboard.equipments.root} />
                  case 'contractors':
                        return <Navigate to={PATH_CONTRACTOR_DASHBOARD.dashboard.timekeeping.root} />
                  case 'rental_owners':
                        return <Navigate to={PATH_RENTAL_OWNER_DASHBOARD.dashboard.overview.root} />
                  default:
                        break;
            }
      }
      
      return <>{children}</>;

}