import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { Box } from '@mui/material';
import React, { useRef, useEffect, useState } from 'react';

mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN;

export default function Map({
  equipments = []
}) {

  const mapContainer = useRef(null);

  const map = useRef(null);

  const [defaultLng, setDefaulLng] = useState(124.73865696979146);

  const [defaultLat, setDefaultLat] = useState(6.368849876225908);

  const [zoom, setZoom] = useState(10);

  const generateDefaultMarker = () => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/outdoors-v12',
      center: [defaultLng.toFixed(4), defaultLat.toFixed(4)],
      zoom: zoom
    });

    const marker = new mapboxgl.Marker()
      .setLngLat([defaultLng.toFixed(4), defaultLat.toFixed(4)])

      .addTo(map.current);

  }

  // for Pop ups
  // .setPopup(new mapboxgl.Popup().setHTML("<h6>Hello World!</h6>"))
  // marker.togglePopup();

  const generateEquipmentMarkers = () => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/outdoors-v12',
      center: [defaultLng.toFixed(4), defaultLat.toFixed(4)],
      zoom: zoom
    });

    for (const coordinates of equipments) {
      new mapboxgl.Marker()
        .setLngLat([coordinates.current_lng.toFixed(4), coordinates.current_lat.toFixed(4)])
        .addTo(map.current);
    }
  }

  useEffect(() => {
    if (equipments.length > 0) {
      generateEquipmentMarkers()
    } else {
      generateDefaultMarker()
    }
  });


  return (
    <Box>
      <div
        ref={mapContainer}
        className="map-container"
      />
    </Box>
  )
}