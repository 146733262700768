import { combineReducers } from 'redux';
import { authentication } from './authentication';
import { global } from './global';
import { project } from './project';
import { equipment } from './equipment';

export default combineReducers({
  authentication,
  project,
  equipment,
  global
});