import { createContext } from "react";
import { globalActions, globalInitialState } from "../redux";
import { useDispatch, useSelector } from "react-redux";

const GlobalContext = createContext({
      ...globalInitialState,
      reloadPage: () => null,
      toggleNavbar: () => null
});

const GlobalProvider = ({ children }) => {

      const dispatch = useDispatch();

      const state = useSelector((state) => state.global);

      const toggleNavbar = () => {
            dispatch(globalActions.toggleDrawer());
      }

      const reloadPage = () => {
            dispatch(globalActions.reloadPage());
      }

      return (
            <GlobalContext.Provider
                  value={{
                        ...state,
                        toggleNavbar,
                        reloadPage
                  }}
            >
                  {children}
            </GlobalContext.Provider>
      );
};

export { GlobalContext, GlobalProvider }