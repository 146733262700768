import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"

export default function EquipmentRequestTable() {

      return (
            <TableContainer sx={{ minWidth: '50vh', maxHeight: '50vh' }}>
                  <Table stickyHeader>
                        <TableHead>
                              <TableRow>
                                    {
                                          tableHeader.map((row) => (
                                                <TableCell align="left" key={row.id}>
                                                      <Typography variant="subtitle2" color={'#51cbce'} fontWeight={700}>{row.label}</Typography>
                                                </TableCell>
                                          ))
                                    }
                              </TableRow>
                        </TableHead>
                        <TableBody>
                              {
                                    tableRow.map(row => (
                                          <TableRow
                                                key={row.id}
                                                hover
                                                sx={{ 
                                                      '&:last-child td, &:last-child th': { 
                                                            border: 0 
                                                      },
                                                      cursor: 'pointer'
                                                }}
                                          >
                                                <TableCell align="left">{row.project}</TableCell>
                                                <TableCell align="left">{row.request_date}</TableCell>
                                                <TableCell align="left">{row.mobilization}</TableCell>
                                                <TableCell align="left">{row.status}</TableCell>
                                                <TableCell align="left">{row.in_charge}</TableCell>
                                          </TableRow>
                                    ))
                              }
                        </TableBody>
                  </Table>
            </TableContainer>
      )
}



const tableRow = [
      {
            id: 1,
            project: 'Construction of 2 classrooms',
            request_date: 'June 15, 2023',
            mobilization: 'June 26, 2023',
            status: 'Pending',
            in_charge: '--'
      },
      {
            id: 2,
            project: 'Tupi Flood Control',
            request_date: 'June 15, 2023',
            mobilization: 'June 26, 2023',
            status: 'In Progress',
            in_charge: 'Mong'
      }
];



const tableHeader = [
      { id: 1, label: 'PROJECT' },
      { id: 2, label: 'REQUEST DATE' },
      { id: 3, label: 'MOBILIZATION' },
      { id: 4, label: 'STATUS' },
      { id: 5, label: 'IN-CHARGE' }
]