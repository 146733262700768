import { Alert, AlertTitle, Avatar, Box, Button, Card, Container, Divider, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import Logo from '../../../assets/logo192.png';
import { useState } from "react";
import useAuth from "../../../hooks/useAuth";
import { LoadingButton } from "@mui/lab";
import Page from "../../../components/Page";
import { FormProvider, FormTextField } from "../../../components/hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Iconify from '../../../components/Iconify'
import { useNavigate } from "react-router-dom";
import { PATH_AUTH } from "../../../routes/paths";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";

export default function Login() {

      const { login, errorMessage } = useAuth();

      const navigate = useNavigate();

      const [showPassword, setShowPassword] = useState(false);

      const LoginSchema = Yup.object().shape({
            username: Yup.string().required('Username is required'),
            password: Yup.string().required('Password is required')
      });

      const defaultValues = {
            username: '',
            password: ''
      }

      const methods = useForm({
            resolver: yupResolver(LoginSchema),
            defaultValues
      });

      const {
            handleSubmit,
            formState: { error, isSubmitting }
      } = methods;

      const onSubmit = async (data) => {
            await login(data.username, data.password);
      }

      const handleFacebookLogin = () => {
            window.location.href = process.env.REACT_APP_FACEBOOK_LOGIN_URL;
      }

      const handleGoogleLogin = () => {
            window.location.href = process.env.REACT_APP_GOOGLE_LOGIN_URL;
      }

      return (
            <Page title="Login">
                  <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Container
                              maxWidth="sm"
                        >
                              <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{ minHeight: '100vh' }}
                              >
                                    <Grid item xs={3}>
                                          <Box
                                                component={Card}
                                                width={400}
                                                height={'100%'}
                                                paddingX={5}
                                                paddingY={5}
                                                sx={{ borderRadius: '12px' }}
                                          >
                                                <Stack
                                                      direction={'row'}
                                                      justifyContent={'center'}
                                                      alignItems={'center'}
                                                      spacing={2}
                                                >
                                                      <Avatar src={Logo} />
                                                      <Typography variant="h5">DEMITECH</Typography>

                                                </Stack>

                                                <Divider sx={{ my: 2, mx: 2 }} />

                                                <Stack
                                                      direction={'column'}
                                                      spacing={2}
                                                      sx={{
                                                            mt: 2
                                                      }}
                                                >
                                                      <Box
                                                            sx={{
                                                                  width: '20'
                                                            }}
                                                      >
                                                            {
                                                                  (errorMessage?.message) &&
                                                                  <Alert severity="error">
                                                                        {errorMessage?.message}
                                                                  </Alert>
                                                            }
                                                      </Box>

                                                      <FormTextField
                                                            placeholder="Username"
                                                            label="Username"
                                                            name={'username'}
                                                            inputProps={{
                                                                  autoComplete: 'new-password',
                                                                  form: {
                                                                        autocomplete: 'off'
                                                                  }
                                                            }}
                                                      />

                                                      <FormTextField
                                                            label="Password"
                                                            placeholder="Password"
                                                            type={showPassword ? "text" : "password"}
                                                            name={"password"}
                                                            InputProps={{
                                                                  endAdornment:
                                                                        <InputAdornment position="end">
                                                                              <IconButton
                                                                                    onClick={() => setShowPassword(!showPassword)}
                                                                                    edge="end"
                                                                              >
                                                                                    <Iconify
                                                                                          icon={
                                                                                                showPassword
                                                                                                      ? "eva:eye-fill"
                                                                                                      : "eva:eye-off-fill"
                                                                                          }
                                                                                    />
                                                                              </IconButton>
                                                                        </InputAdornment>
                                                            }}
                                                      />

                                                      <LoadingButton
                                                            variant="contained"
                                                            disableRipple
                                                            disableTouchRipple
                                                            type="submit"
                                                            loading={isSubmitting}
                                                            sx={{
                                                                  backgroundColor: '#51cbce',
                                                                  textTransform: 'none',
                                                                  background: '#51cbce',
                                                                  '&:hover': {
                                                                        background: '#51cbce'
                                                                  }
                                                            }}
                                                      >
                                                            Login
                                                      </LoadingButton>

                                                      <Stack
                                                            justifyContent={'flex-end'}
                                                            alignItems={'flex-end'}
                                                      >
                                                            <Button
                                                                  variant="text"
                                                                  disableRipple
                                                                  sx={{
                                                                        textTransform: 'none',
                                                                        '&:hover': {
                                                                              background: 'none'
                                                                        }
                                                                  }}
                                                            >
                                                                  Forgot Password?
                                                            </Button>
                                                      </Stack>

                                                      <Stack
                                                            direction={'column'}
                                                            spacing={1}
                                                            sx={{
                                                                  pt: 5
                                                            }}
                                                      >
                                                            <FacebookLoginButton
                                                                  size="35px"
                                                                  iconSize="20px"
                                                                  align="center"
                                                                  style={{ fontSize: '14px' }}
                                                                  onClick={() => handleFacebookLogin()}
                                                            />
                                                            <Divider light><Typography variant="caption" sx={{ pb: 4 }}>OR</Typography></Divider>

                                                            <GoogleLoginButton
                                                                  size="35px"
                                                                  iconSize="20px"
                                                                  align="center"
                                                                  style={{ fontSize: '14px' }}
                                                                  onClick={() => handleGoogleLogin()}
                                                            />
                                                      </Stack>

                                                      <Stack
                                                            direction={'column'}
                                                            spacing={2}
                                                            sx={{
                                                                  pt: 6
                                                            }}
                                                      >
                                                            <Divider><Typography variant="subtitle1" sx={{ px: 2 }}>Don't have an Demitech account?</Typography></Divider>
                                                            <Button
                                                                  variant="outlined"
                                                                  size="small"
                                                                  color="success"
                                                                  disableRipple
                                                                  disableTouchRipple
                                                                  sx={{
                                                                        borderRadius: '25px'
                                                                  }}
                                                                  onClick={() => navigate(PATH_AUTH.register)}
                                                            >
                                                                  Sign Up
                                                            </Button>
                                                      </Stack>
                                                </Stack>
                                          </Box>
                                    </Grid>
                              </Grid>

                        </Container>
                  </FormProvider>
            </Page>
      )
}