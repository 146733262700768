import { axiosInstance as axios } from '../../utils/axios';


export const fetchEquipments = async(ownerId) => {
  const response = await axios.get(`/rental-owners/equipments/${ownerId}`);
  return response.data;
}

export const getEquipment = async(equipmentId) => {
  const response = await axios.get(`/rental-owners/equipments/${equipmentId}`);
  return response.data;
}

export const storeEquipment = async(payload) => {
  const response = await axios.post('/rental-owners/equipments', payload);
  return response.data;
}

export const updateEquipment = async(payload, equipmentId) => {
  const response = await axios.put(`/rental-owners/equipments/${equipmentId}`, payload);
  return response.data;
}

export const deleteEquipment = async(equipmentId) => {
  const response = await axios.delete(`/rental-owners/equipments/${equipmentId}`);
  return response.data;
}