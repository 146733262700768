import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography } from "@mui/material";
import useProject from "../../../../../hooks/useProject";
import { useDispatch } from "react-redux";
import { projectActions } from "../../../../../redux";
import CloseIcon from '@mui/icons-material/Close';
import AutoComplete from "../../../../../components/Autocomplete";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

export default function AddContractorModal() {

      const dispatch = useDispatch();

      const [contractorPayload, setContractorPayload] = useState([]);

      const [isSubmitting, setIsSubmitting] = useState(false);

      const {
            isAddContractorModalOpen,
            saveContractor
      } = useProject();

      const handleContractorFields = (e) => {
            setContractorPayload({
                  ...contractorPayload,
                  [e.target.name]: e.target.value
            });
      }

      const handleSelectedPcabCategory = (e, v) => {
            setContractorPayload({
                  ...contractorPayload,
                  pcab_category: v?.label
            });
      }

      const handleCreateContractor = async() => {
            setIsSubmitting(true);
            const payload = {
                  company_name: contractorPayload.company_name,
                  company_address: contractorPayload.company_address,
                  lat: contractorPayload.lat,
                  lng: contractorPayload.lng,
                  pcab_category: contractorPayload.pcab_category,
                  check_payments_to: contractorPayload.check_payments_to
            };

            await saveContractor(payload);
            setIsSubmitting(false)
      }

      return (
            <Dialog
                  maxWidth={'sm'}
                  fullWidth
                  open={isAddContractorModalOpen}
                  onClose={() => dispatch(projectActions.openAddContractorModal())}
            >
                  <DialogTitle>
                        <Box
                              display={'flex'}
                              justifyContent={'space-between'}
                              alignItems={'center'}
                        >
                              <Typography variant="h6">Add Contractor</Typography>
                              <IconButton onClick={() => dispatch(projectActions.openAddContractorModal())}>
                                    <CloseIcon />
                              </IconButton>
                        </Box>
                  </DialogTitle>
                  <DialogContent>
                        <Box
                              minHeight={'40vh'}
                              sx={{
                                    py: 2
                              }}
                        >
                              <Stack
                                    direction={'column'}
                                    spacing={2}
                              >
                                    <TextField
                                          variant="outlined"
                                          name="company_name"
                                          label="Company Name"
                                          placeholder="Company Name"
                                          onChange={(e) => handleContractorFields(e)}
                                    />

                                    <TextField
                                          variant="outlined"
                                          name="company_address"
                                          label="Company Address"
                                          placeholder="Company Address"
                                          onChange={(e) => handleContractorFields(e)}
                                    />

                                    <Stack
                                          direction={'row'}
                                          spacing={2}
                                    >
                                          <TextField
                                                name="lat"
                                                variant="outlined"
                                                label="Latitude"
                                                placeholder="Latitude"
                                                fullWidth
                                                onChange={(e) => handleContractorFields(e)}
                                          />

                                          <TextField
                                                name="lng"
                                                variant="outlined"
                                                label="Longitude"
                                                fullWidth
                                                placeholder="Longitude"
                                                onChange={(e) => handleContractorFields(e)}
                                          />
                                    </Stack>

                                    <AutoComplete
                                          label={'PCAB Category'}
                                          fullWidth
                                          listItem={pcab_category}
                                          placeholder="Select Category"
                                          onChange={(e, v) => handleSelectedPcabCategory(e, v)}
                                    />

                                    <TextField
                                          name="check_payments_to"
                                          variant="outlined"
                                          label="Check Payments To"
                                          placeholder="Check Payments To"
                                          onChange={(e) => handleContractorFields(e)}
                                    />

                              </Stack>

                              <LoadingButton
                                    variant="contained"
                                    fullWidth
                                    loading={isSubmitting}
                                    onClick={() => handleCreateContractor()}
                                    sx={{
                                          borderRadius: '10px',
                                          background: '#51cbce',
                                          marginTop: '20px',
                                          '&:hover': {
                                                background: '#51cbce'
                                          }
                                    }}
                              >
                                    Save
                              </LoadingButton>
                        </Box>
                  </DialogContent>
            </Dialog>
      )
}

const pcab_category = [
      { id: 1, label: 'AAAA' },
      { id: 2, label: 'AAA' },
      { id: 3, label: 'AA' },
      { id: 4, label: 'A' },
      { id: 5, label: 'B' },
      { id: 6, label: 'C' },
      { id: 7, label: 'D' }
]