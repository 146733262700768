import { globalTypes } from "./global.types";


export const globalActions = {
      toggleDrawer,
      reloadPage
}


function toggleDrawer() {
      return dispatch => {
            dispatch({
                  type: globalTypes.TOGGLE_DRAWER
            });
      };
};

function reloadPage() {
      return dispatch => {
            dispatch({
                  type: globalTypes.RELOADING_PAGE
            });
      };
};