export const setAuthUser = (user) => localStorage.setItem('authUser', JSON.stringify(user));
export const setToken = (token) => localStorage.setItem('token', token);
export const setTokenExpiration = (expireAt) => localStorage.setItem('token_expiration', expireAt);

export const getToken = () => {
      return localStorage.getItem('token')
}      

export const getTokenExpiration = () => {
      return localStorage.getItem('token_expiration');
}

export const getAuthUser = () => {
      const user = localStorage.getItem('authUser');
      return JSON.parse(user);
}

export const clearAuth = () => {
      localStorage.clear();
}