import { authTypes } from "./authentication.types";
import { 
      login as apiLogin, 
      logout as apiLogout,
      googleAccountRegistration,
      googleAccountSignIn,
      facebookAccountRegistration,
      facebookAccountSignIn
} from '../../api/authentication';
import { clearAuth, getAuthUser, getTokenExpiration, setAuthUser, setToken, setTokenExpiration } from '../../utils/auth';
import { differenceInMinutes, parseJSON } from "date-fns";


export const authActions = {
      login,
      logout,
      verifyAuth,
      clearAuthIfTokenIsNotValid,
      validateToken,
      googleSignin,
      googleRegistration,
      facebookSignin,
      facebookRegistration
};


function googleSignin(token) {
      return async (dispatch) => {
            await googleAccountSignIn(token).then(response => {
                  const {
                        result,
                        token,
                        token_expiration
                  } = response;

                  setAuthUser(result);
                  setToken(token);
                  setTokenExpiration(token_expiration);

                  dispatch({
                        type: authTypes.GOOGLE_SIGNIN_SUCCESS,
                        payload: { ...result }
                  });
            }).catch(() => {
                  console.log('Unable to signin');
            })
      }
}

function googleRegistration(token, userType) {
      return async (dispatch) => {
            await googleAccountRegistration(token, userType).then(response => {

                  const {
                        result,
                        token,
                        token_expiration
                  } = response;

                  setAuthUser(result);
                  setToken(token);
                  setTokenExpiration(token_expiration);

                  dispatch({
                        type: authTypes.GOOGLE_REGISTRATION_SUCCESS,
                        payload: { ...result }
                  });

            }).catch(() => {
                  console.log("Error in registration");
            })
      }
}

function facebookSignin(token) {
      return async (dispatch) => {
            await facebookAccountSignIn(token).then(response => {
                  const {
                        result,
                        token,
                        token_expiration
                  } = response;

                  setAuthUser(result);
                  setToken(token);
                  setTokenExpiration(token_expiration);

                  dispatch({
                        type: authTypes.FACEBOOK_SIGNIN_SUCCESS,
                        payload: { ...result }
                  });
            }).catch(() => {
                  console.log('Unable to signin');
            })
      }
}

function facebookRegistration(token, userType) {
      return async (dispatch) => {
            await facebookAccountRegistration(token, userType).then(response => {

                  const {
                        result,
                        token,
                        token_expiration
                  } = response;

                  setAuthUser(result);
                  setToken(token);
                  setTokenExpiration(token_expiration);

                  dispatch({
                        type: authTypes.FACEBOOK_REGISTRATION_SUCCESS,
                        payload: { ...result }
                  });

            }).catch(() => {
                  console.log("Error in registration");
            })
      }
}


function login({ username, password }) {
      return async (dispatch) => {

            await apiLogin({ username, password }).then(response => {
                  const {
                        result,
                        token,
                        token_expiration
                  } = response;

                  setAuthUser(result);
                  setToken(token);
                  setTokenExpiration(token_expiration);
                  dispatch(success(result));

            }).catch((error) => {
                  dispatch(failed(error));
            });

      };

      function success(response) {
            return {
                  type: authTypes.LOGIN_SUCCESS,
                  payload: { ...response }
            };
      };

      function failed(response) {
            return {
                  type: authTypes.LOGIN_FAILURE,
                  payload: response
            }
      }
};

function verifyAuth() {
      return dispatch => {
            const user = getAuthUser();
            if (user) {
                  dispatch({
                        type: authTypes.VERIFY_AUTH_SUCCESS,
                        payload: { ...user }
                  });
            } else {
                  dispatch({
                        type: authTypes.VERIFY_AUTH_FAILED,
                        payload: {}
                  })
            };
      };
};

function clearAuthIfTokenIsNotValid() {
      return dispatch => {
            clearAuth();

            dispatch({
                  type: authTypes.CLEAR_CACHE
            });

            window.location.reload();
      }
}

function validateToken() {
      return dispatch => {
            const expiresAt = getTokenExpiration();
            const user = getAuthUser();

            if (expiresAt && user) {
                  const expireAtDate = parseJSON(expiresAt);
                  const rightNow = new Date();
                  const diffMinutes = differenceInMinutes(expireAtDate, rightNow);

                  if (diffMinutes < 0) {
                        clearAuth();
                        dispatch({
                              type: authTypes.VALIDATE_TOKEN_FAILED
                        });
                  } else {
                        dispatch({
                              type: authTypes.VALIDATE_TOKEN_SUCCESS
                        });
                  }
            } else {
                  clearAuth();
                  dispatch({
                        type: authTypes.VALIDATE_TOKEN_FAILED
                  });
            }
      }
}

function logout() {
      return async (dispatch) => {
            await apiLogout().then(() => {
                  clearAuth();

                  dispatch({
                        type: authTypes.LOGOUT_SUCCESS,
                        payload: {}
                  });

            }).catch(() => {
                  console.log('Unable to logout')
            })
      };
};




