import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"

export default function DeMobilizationTable() {

      return (
            <TableContainer sx={{ minWidth: '50vh', maxHeight: '50vh' }}>
                  <Table stickyHeader>
                        <TableHead>
                              <TableRow>
                                    {
                                          tableHeader.map((row) => (
                                                <TableCell align="left" key={row.id}>
                                                      <Typography variant="subtitle2" color={'#51cbce'} fontWeight={700}>{row.label}</Typography>
                                                </TableCell>
                                          ))
                                    }
                              </TableRow>
                        </TableHead>
                        <TableBody>
                              {
                                    tableRow.map(row => (
                                          <TableRow
                                                key={row.id}
                                                hover
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                          >
                                                <TableCell align="left">{row.equipment}</TableCell>
                                                <TableCell align="left">{row.project}</TableCell>
                                                <TableCell align="left">{row.owner}</TableCell>
                                                <TableCell align="left">{row.contractor}</TableCell>
                                                <TableCell align="left">{row.origin}</TableCell>
                                                <TableCell align="left">{row.destination}</TableCell>
                                                <TableCell align="left">₱ {row.estimated_cost}</TableCell>
                                          </TableRow>
                                    ))
                              }
                        </TableBody>
                  </Table>
            </TableContainer>
      )
}





const tableRow = [
      {
            id: 1,
            equipment: 'DTC-0001 PC30-3',
            project: 'Lumasal Pipe Laying',
            owner: 'Demitech',
            contractor: 'RIT Construction',
            origin: 'Davao City',
            destination: 'Lumasal, Sarangani',
            estimated_cost: '5,524.00'
      },
];


const tableHeader = [
      { id: 1, label: 'EQUIPMENT' },
      { id: 2, label: 'PROJECT' },
      { id: 3, label: 'OWNER' },
      { id: 4, label: 'CONTRACTOR' },
      { id: 5, label: 'ORIGIN' },
      { id: 6, label: 'DESTINATION' },
      { id: 7, label: 'ESTIMATED COST' }
]