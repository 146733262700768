import { Outlet } from "react-router-dom";
import NavigationBar from "./navbar";
import Header from "./header";
import { Box, CircularProgress, Collapse, Drawer } from "@mui/material";
import { useEffect } from "react";
import useGlobal from "../../hooks/useGlobal";
import useResponsive from "../../hooks/useResponsive";

export default function ContractorLayout() {
  const { isNavBarOpen, isPageLoading, reloadPage, toggleNavbar } = useGlobal();

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (isPageLoading) {
      setTimeout(() => {
        reloadPage();
      }, 500);
    }
  }, [isPageLoading]);

  return (
    <Box
      sx={{
        display: "-webkit-box",
        minHeight: "100vh",
      }}
    >
      {isDesktop && (
        <Box
          sx={{
            position: "relative",
            bgcolor: "#2c2c2c",
          }}
        >
          <Collapse in={isNavBarOpen} orientation="horizontal">
            <NavigationBar />
          </Collapse>
        </Box>
      )}

      {!isDesktop && (
        <Drawer
          open={isNavBarOpen}
          onClose={() => toggleNavbar()}
          PaperProps={{
            sx: {
              display: "-webkit-box",
              zIndex: 99999,
              boxShadow: (theme) => theme.customShadows.z24,
            },
          }}
        >
          <Box
            sx={{
              bgcolor: "#2c2c2c",
              width: 300,
            }}
          >
            <NavigationBar />
          </Box>
        </Drawer>
      )}

      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <Header />

        {isPageLoading ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ px: 20, py: 20 }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box marginX={4}>
            <Outlet />
          </Box>
        )}
      </Box>
    </Box>
  );
}
