import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography } from "@mui/material";
import useEquipment from "../../../../../hooks/useEquipment";
import { useDispatch } from "react-redux";
import { equipmentActions } from "../../../../../redux";
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";


export default function EquipmentOwnerModal() {

      const dispatch = useDispatch();

      const {
            isAddEquipmentOwnerModalOpen
      } = useEquipment();

      return (
            <Dialog
                  fullWidth
                  maxWidth={'sm'}
                  open={isAddEquipmentOwnerModalOpen}
                  onClose={() => dispatch(equipmentActions.equipmentOwnerModal())}
            >
                  <DialogTitle>
                        <Box
                              display={'flex'}
                              justifyContent={'space-between'}
                              alignItems={'center'}
                        >
                              <Typography variant="h6">Add Equipment Owner</Typography>
                              <IconButton onClick={() => dispatch(equipmentActions.equipmentOwnerModal())}>
                                    <CloseIcon />
                              </IconButton>
                        </Box>
                  </DialogTitle>

                  <DialogContent>
                        <Box
                              minHeight={'10vh'}
                              sx={{
                                    py: 2
                              }}
                        >
                              <Stack
                                    direction={'column'}
                                    spacing={2}
                              >
                                    <TextField
                                          variant="outlined"
                                          name="check_payments_to"
                                          label="Check payments to"
                                          placeholder="Check payments to"
                                    />

                                    <LoadingButton
                                          variant="contained"
                                          fullWidth
                                          onClick={() => dispatch(equipmentActions.equipmentOwnerModal())}
                                          sx={{
                                                borderRadius: '10px',
                                                background: '#51cbce',
                                                marginTop: '20px',
                                                '&:hover': {
                                                      background: '#51cbce'
                                                }
                                          }}
                                    >
                                          Save
                                    </LoadingButton>
                              </Stack>
                        </Box>
                  </DialogContent>
            </Dialog>
      )
}