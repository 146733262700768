import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Box, Card, Grid, Container, Stack, Avatar, Typography, Divider } from "@mui/material";

import Logo from '../../../../../assets/logo192.png';
import Page from "../../../../../components/Page";
import { FormProvider, FormSelect } from "../../../../../components/hook-form";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH_AUTH } from "../../../../../routes/paths";
import { useDispatch } from "react-redux";
import { authActions } from "../../../../../redux";

export default function GoogleAccountRegistration() {

   const navigate = useNavigate();

   const dispatch = useDispatch();

   const { pathname } = useLocation();

   const token = pathname.split('/')[4];
   
   const GoogleUserSchema = Yup.object().shape({
      user_type: Yup.string().required('Account type is required')
   });

   const defaultValues = {
      user_type: ''
   }

   const methods = useForm({
      resolver: yupResolver(GoogleUserSchema),
      defaultValues
   })

   const {
      handleSubmit,
      formState: { errors, isSubmitting }
   } = methods;

   const onSubmit = async (data) => {
      await dispatch(authActions.googleRegistration(token, data.user_type));
   }

   return (
      <Page title="Registration">
         <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Container maxWidth="sm">
               <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ minHeight: '100vh' }}
               >
                  <Grid item xs={3}>
                     <Box
                        component={Card}
                        width={400}
                        height={'100%'}
                        paddingX={5}
                        paddingY={5}
                        sx={{ borderRadius: '12px' }}
                     >
                        <Stack
                           direction={'row'}
                           justifyContent={'center'}
                           alignItems={'center'}
                           spacing={2}
                        >
                           <Avatar src={Logo} />
                           <Typography variant="h5">DEMITECH</Typography>

                        </Stack>

                        <Divider sx={{ my: 2, mx: 2 }} />
                        <Stack
                           direction={'column'}
                           spacing={2}
                           sx={{
                              mt: 2
                           }}
                        >

                           <FormSelect
                              name={'user_type'}
                              label={'Choose account type..'}
                           >
                              <option value={""} />
                              {
                                 userType.map(type => {
                                    return (
                                       <option
                                          key={type.id}
                                          value={type.value}
                                       >
                                          {type.label}
                                       </option>
                                    )
                                 })
                              }
                           </FormSelect>

                           <LoadingButton
                              variant="contained"
                              disableRipple
                              disableTouchRipple
                              type="submit"
                              loading={isSubmitting}
                              sx={{
                                 backgroundColor: '#51cbce',
                                 textTransform: 'none',
                                 background: '#51cbce',
                                 '&:hover': {
                                    background: '#51cbce'
                                 }
                              }}
                           >
                              Finish Registration
                           </LoadingButton>
                           
                           <Typography
                              variant="subtitle2"
                              sx={{
                                 textAlign: 'center',
                                 paddingTop: '5px',
                                 cursor: 'pointer',
                                 '&:hover': {
                                    color: '#51cbce'
                                 }
                              }}
                              onClick={() => navigate(PATH_AUTH.login)}
                           >
                              Back to Login
                           </Typography>
                        </Stack>
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </FormProvider>
      </Page>
   )
}

const userType = [
   { id: 'contractors', label: 'Contractor', value: 'contractors' },
   { id: 'rental_owners', label: 'Rental Owner', value: 'rental_owners' },
   { id: 'operators', label: 'Operator', value: 'operators' },
   { id: 'brokers', label: 'Broker', value: 'brokers' },
]