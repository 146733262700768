import { useContext } from "react";

import { AuthContext } from "../context/auth-context";

const useAuth = () => {
      const context = useContext(AuthContext);

      if (!context) throw new Error("Context Error");

      return context;
}

export default useAuth;