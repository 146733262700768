import DrawerComponent from "../../../components/DrawerComponent";
import Page from "../../../components/Page";
import { AddEquipmentDrawer } from "./components/drawerItems";
import RentalOwnerEquipmentsTable from "./components/table";
import { Box, Button, Card, Stack, Typography } from "@mui/material";
import useEquipment from "../../../hooks/useEquipment";
import { useEffect, useState } from "react";
import { fetchEquipments } from "../../../api/rental-owners";
import Swal from "sweetalert2";
import { getAuthUser } from "../../../utils/auth";

export default function RentalOwnerEquipments() {

  const {
    isAddEquipmentDrawerOpen,
    reloadRentalOwnerEquipmentsTable,
    openAddEquipmentDrawer,
  } = useEquipment();

  const [equipmentList, setEquipmentList] = useState();

  const user = getAuthUser();

  const [isMounted, setIsMounted] = useState(false);

  const handleFetchEquipments = async () => {
    await fetchEquipments(user?.company_id).then(response => {

      setEquipmentList(response);

    }).catch(error => {
      Swal.fire({
        icon: 'error',
        text: 'Unable to fetch equipments'
      });
      setEquipmentList([])
    });
  }

  useEffect(() => {
    handleFetchEquipments();
  }, [reloadRentalOwnerEquipmentsTable])

  useEffect(() => {
    if(!isMounted) {
      handleFetchEquipments()
      setIsMounted(true)
    }
  }, [])

  return (
    <Page title="Equipments">
      <Box
        component={Card}
        borderRadius={'12px'}
        paddingX={2}
        paddingY={2}
      >

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            my: 1
          }}
        >
          <Stack
            direction={'column'}
            spacing={3}
          >
            <Button
              variant="contained"
              disableRipple
              disableTouchRipple
              fullWidth
              onClick={() => openAddEquipmentDrawer()}
              size="medium"
              sx={{
                borderRadius: '10px',
                textTransform: 'none',
                background: '#51cbce',
                width: '12%',
                '&:hover': {
                  background: '#51cbce'
                }
              }}
            >
              Enroll New Equipment
            </Button>

            <RentalOwnerEquipmentsTable equipmentList={equipmentList}/>
          </Stack>



          <DrawerComponent
            drawerLabel={'Enroll New Equipment'}
            onOpen={isAddEquipmentDrawerOpen}
            width="70vh"
            onClose={() => openAddEquipmentDrawer()}
          >
            <AddEquipmentDrawer />
          </DrawerComponent>

        </Box>
      </Box>
    </Page>
  )
}