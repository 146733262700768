export const projectTypes = {
      IS_PROJECT_SAVE: 'IS_PROJECT_SAVE',
      IS_FETCHING_PROJECTS: 'IS_FETCHING_PROJECTS',

      IS_EDIT_PROJECT: 'IS_EDIT_PROJECT',
      IS_ADD_PROJECT: 'IS_ADD_PROJECT',

      IS_CONTRACTOR_SAVE_SUCCESS: 'IS_CONTRACTOR_SAVE_SUCCESS',
      IS_CONTRACTOR_SAVE_FAILED: 'IS_CONTRACTOR_SAVE_FAILED',

      IS_CONTRACTOR_UPDATE_SUCCESS: 'iS_CONTRACTOR_UPDATE_SUCCESS',
      IS_CONTRACTOR_UPDATE_FAILED: 'IS_CONTRACTOR_UPDATE_FAILED',

      IS_PROJECT_CREATE_SUCCESS: 'IS_PROJECT_CREATE_SUCCESS',
      IS_PROJECT_CREATE_FAILED: 'IS_PROJECT_CREATE_FAILED',

      IS_PROJECT_UPDATE_SUCCESS: 'IS_PROJECT_UPDATE_SUCCESS',
      IS_PROJECT_UPDATE_FAILED: 'IS_PROJECT_UPDATE_FAILED',

      OPEN_ADD_CONTRACTOR_MODAL: 'OPEN_ADD_CONTRACTOR_MODAL',

      SELECTED_CONTRACTOR: 'SELECTED_CONTRACTOR'
}